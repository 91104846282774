import React from 'react'

function DoubleSelectInput({ 
    label1,
    label2,
    name1,
    name2,
    handleChange,
    children1,
    children2,
    transferTypeValue

}) {
  return (
    <div className='input_group_otc_double'>
        <div className='input_group_double'>
            <p className='input_text'>{label1}</p>
           
                <select 
                className='half_input' 
                name={name1}
                onChange={handleChange}
                
                >
                    {children1}
        
                </select> 
        </div>
        <div className='input_group_double'>
            <p className='input_text'>{label2}</p>
            <select 
              className='half_input' 
              name={name2}
              onChange={handleChange} 
              value={transferTypeValue}
            >
              {children2}
            </select>
        </div>
    </div>
  )
}

export default DoubleSelectInput