import React, { useEffect, useState } from 'react'
import DoubleInputGroup from './DoubleInputGroup'
import SingleInputGroup from './SingleInputGroup'
import BusinessAddress from './BusinessAddress'
import { toast } from 'react-toastify';
import DoubleSelectInput from './DoubleSelectInput';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { BeatLoader } from 'react-spinners';

function PayoutBeneficiaryAdd({setIsPayout,setBankDetailInput,setIsBenAdd,setIsBankDetail,beneficiaryInput,setBeneficiaryInput,countries,setIsPayoutBenSubmited}) {
  const [isLoading,setIsLoading] = useState(false)
  const { currentUser } = useSelector((state) => state?.user);
    useEffect(() => {
        // Default the country to "United States" and set transfer type options accordingly
        setBeneficiaryInput((prevFormData) => ({
          ...prevFormData,
          beneficiary_country: "United States",
        }));
        updateTransferTypeOptions("United States");
        // eslint-disable-next-line
      }, []);
      
     const [page,setPage] = useState("");
     const [transferTypeOptions, setTransferTypeOptions] = useState([]);
     const [selectedTransferType, setSelectedTransferType] = useState("");


     const handleInputChange = (event) => {
        const { name, value } = event.target;
        setBeneficiaryInput((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
    
        if (name === "beneficiary_country") {
          setSelectedTransferType(""); // Reset transfer type
          updateTransferTypeOptions(value);
        }
    
        if (name === "transfer_type") {
          setSelectedTransferType(value);
        }
      };

      const updateTransferTypeOptions = (country) => {
        let options = [
          <option key="placeholder" value="">Select Option</option>
        ];
        
        switch (country) {
          case "United States":
            options = options.concat([
              <option key="fed_wire" value="fed_wire">Fed Wire</option>,
              <option key="ach" value="ach">ACH</option>
            ]);
            break;
          case "Canada":
            options = options.concat([
              <option key="interac" value="interac">Interac</option>,
              <option key="wire" value="wire">Wire</option>
            ]);
            break;
          default:
            options = options.concat([
              <option key="wire" value="wire">Wire</option>
            ]);
            break;
        }
        
        setTransferTypeOptions(options);
      };
    




     const handleClose= ()=>{
        setIsBenAdd(false)
     }

     const handleBenDetail = ()=>{
        const {account_no,routing,beneficiary_name,beneficiary_address_1,beneficiary_city,beneficiary_state,beneficiary_country,beneficiary_postal_code,transfer_type} = beneficiaryInput;
        if(account_no === "") return toast.error("account Number must not be empty",{position: "top-left", pauseOnHover: true})
        if(transfer_type === "") return toast.error("Transfer Type is required",{position: "top-left", pauseOnHover: true})
        if(routing === "") return toast.error("Routing Number must not be empty",{position: "top-left", pauseOnHover: true})
        if(beneficiary_name === "") return toast.error("Beneficiary Name must not be empty",{position: "top-left", pauseOnHover: true})
        if(beneficiary_address_1 === "") return toast.error("Address one must not be empty",{position: "top-left", pauseOnHover: true})
        if(beneficiary_city === "") return toast.error("Beneficiary City must not be empty",{position: "top-left", pauseOnHover: true})
        if(beneficiary_state === "") return toast.error("Beneficiary State must not be empty",{position: "top-left", pauseOnHover: true})
        if(beneficiary_country === "") return toast.error("Beneficiary Country must not be empty",{position: "top-left", pauseOnHover: true})
        if(beneficiary_postal_code === "") return toast.error("Beneficiary postal code must not be empty",{position: "top-left", pauseOnHover: true})
        
        setIsBankDetail(true);
     }


    //  Interac

    const handleInterac = async()=>{
      
      setIsLoading(true)
      const {beneficiary_name,email,phone_number,transfer_type} = beneficiaryInput
      if(beneficiary_name === "") return toast.error("Beneficiary Name is required",{position: "top-left", pauseOnHover: true})
      if(email === "") return toast.error("Email is required",{position: "top-left", pauseOnHover: true})
      if(phone_number === "") return toast.error("Phone number is required",{position: "top-left", pauseOnHover: true})
      if(transfer_type === "") return toast.error("Transfer Type is required",{position: "top-left", pauseOnHover: true})

      const data = {
        phone_no: phone_number,
        email:email,
        beneficiary_name:beneficiary_name,
        transfer_type:transfer_type,
        bank_name:"interac"
      }

      try{
        const headers = {
          api_key: currentUser?.user?.merchantKey,
        };

        const url = "https://flick-app.com/merchant/save-beneficiary-interac";
        let res = await axios.post(url,{...data},{ headers: headers});

        console.log("interac success: ",res.data)
        setIsLoading(false);
        setIsPayoutBenSubmited(true);
      }catch(err){
        setIsLoading(false);
        console.log(err);
        const errorMessage = err?.
        response?.data?.message || "unable to add";
        
        toast.error(errorMessage,{position: "top-left", pauseOnHover: true})
      }
    
    }
  return (
    <div className='balance_modal'  onClick={setIsBenAdd.bind(this, false)} >
        <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
          <div className='balance_modal_wrapper'>
              <img 
              onClick={setIsBenAdd.bind(this, false)}
              className='otc_back' src="/images/otc/backIcon.svg" alt='close' 
              />
            
            <div className="modal-title">
                <div className='step_title'>
                    <div >
                        <p className="enter-amount">Make a Payout</p>
                        <p className="enter-an-amount">Please provide the details of the bank for this payout.</p>
                    </div>
                    <img src='/images/otc/step_one.svg' alt='' />
                </div>
            </div>

            <div className='otcform_container'>    

                <DoubleSelectInput 

                    label1="Country"
                    label2="Transfer Type"
                    name1="beneficiary_country"
                    name2="transfer_type"
                    handleChange={handleInputChange}

                    children1={
                        <>
                              
                               <option value="United States">United States</option>
                                <option value="United Kingdom">United Kingdom</option>
                                <option value="China">China</option>
                                {
                                    countries.map((country)=>(
                                        <option value={country.name}>{country.name}</option>
                                    ))
                                }
                        </>
                    }
                    children2={transferTypeOptions}
                    transferTypeValue={selectedTransferType}
                />

                {
                    selectedTransferType === "interac" ? (
                        <>
                          <SingleInputGroup label="Email" handleChange={handleInputChange} name="email" placeholder="Enter email" />
                          <SingleInputGroup label="Phone Number" handleChange={handleInputChange} name="phone_number" placeholder="Enter phone number" />
                          <SingleInputGroup label="Beneficiary Name" handleChange={handleInputChange} name="beneficiary_name" placeholder="Enter beneficiary name" />
                        </>
                      ) :(

                        <>
                        
                        <DoubleInputGroup label1="Account Number" label2="Routing Number" handleChange={handleInputChange} name1="account_no" name2="routing" placeholder1="22488136902667" placeholder2="23690867"/>
                        <SingleInputGroup label="Beneficiary Account Name" handleChange={handleInputChange} name="beneficiary_name" placeholder="Ayomide Ajibade"/>
                        <BusinessAddress label="Beneficiary Address" />
                        <div style={{display:"flex",flexDirection:"column",marginTop:"20px"}}>
                            <DoubleInputGroup label1="Address Line 1" label2="Address Line 2" handleChange={handleInputChange} name1="beneficiary_address_1" name2="address2" />

                            <DoubleInputGroup label1="City" label2="State" handleChange={handleInputChange} name1="beneficiary_city" name2="beneficiary_state" />

                            <DoubleInputGroup value={beneficiaryInput.beneficiary_country} readOnly label1="Country" label2="Postal Code" handleChange={handleInputChange} name1="country" name2="beneficiary_postal_code" />
                            {/* <DoubleInputGroup select label1="Country" label2="Postal Code" handleChange={handleInputChange} name1="beneficiary_country" name2="beneficiary_postal_code">
                                
                                        <option value="United States">United States</option>
                                        <option value="United Kingdom">United Kingdom</option>
                                        <option value="China">China</option>
                                        {
                                            countries.map((country)=>(
                                                <option value={country.name}>{country.name}</option>
                                            ))
                                        }
                            </DoubleInputGroup> */}
                        </div>
                        </>
                      )
                }

            </div>
            {
               selectedTransferType === "interac" ?
            <div className='otc_btn_modal'>
                <button className='btn otc_btn_cancel' onClick={handleClose} >
                    Cancel
                </button>
                {
                  isLoading ?
                <button className='btn'  >
                  <BeatLoader
                        color="#FFF"
                        cssOverride={{}}
                    />
                </button>:
                <button className='btn' onClick={handleInterac} >
                Confirm
                </button>
                }
              
            </div>:
            <div className='otc_btn_modal'>
                <button className='btn otc_btn_cancel' onClick={handleClose} >
                    Cancel
                </button>
                
                <button className='btn' onClick={handleBenDetail} >
                Confirm
                </button>
              
            </div>
            }

          </div>
        </div>
    </div>
  )
}

export default PayoutBeneficiaryAdd