import React, { useEffect, useState } from 'react'
import DoubleInputGroup from './DoubleInputGroup';
import SingleInputGroup from './SingleInputGroup';
import SelectInputGroup from './SelectInputGroup';
import BusinessAddress from './BusinessAddress';
import SelectInputDesc from './SelectInputDesc';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { BeatLoader, CircleLoader } from 'react-spinners';
import Timer from './Timer';
import DropDownBeneficiary from './DropDownBeneficiary';

function PayoutModal({setIsPayout,setPayoutInput,setIsBankDetail,transferBankInput,setTransferBankInput,setIsBeneficiary,setIsConfirmPortfolio,balanceData,banks,setPayoutId,setPayoutResult,setSelectedBeneficiary,setIsConfirmBankDetial,setIsBenAdd,addBeneficiary,beneficiaryData,countries,setFxAmount}) {
    const [page,setPage] = useState("")

    const [shouldFetchAccountName, setShouldFetchAccountName] = useState(false);
    const { currentUser } = useSelector((state) => state?.user);
    const [isLoading,setIsLoading] = useState(false);
    // const [isPayout,setIsPayout] = useState(false);
    const [isNairPayout,setIsNairaPayout] = useState(false)
    const [amount,setAmount] = useState('')

   

    useEffect(() => {
        if (shouldFetchAccountName) {
          fetchAccountName();
        }

        // eslint-disable-next-line
      }, [shouldFetchAccountName]);

    const  handleInputChange = (event)=>{
        const { name, value } = event.target;
         
        if (name === 'amount') {
            // Update the state with the formatted value
            const valueWithoutCommas = value.replace(/,/g, '');
            setAmount(valueWithoutCommas);
            setFxAmount(valueWithoutCommas);
            // Add commas
            const formattedValue = valueWithoutCommas.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            
            // Update the state with the formatted value
            setTransferBankInput((prevFormData) => ({
            ...prevFormData,
            [name]: formattedValue,
            }));
            
            setPayoutInput((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
            
          } else {
            // For other fields, just update the state normally
            setTransferBankInput((prevFormData) => ({
                ...prevFormData,
                [name]: value,
                ...(name === 'balance' && { currency: value })
            }));
            setPayoutInput((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
          }

        if(transferBankInput.currency==="NGN"){
            if (name === 'account_number' && value.length === 10) {
                setIsLoading(true);
                setShouldFetchAccountName(true);
            } else {
            setShouldFetchAccountName(false);
            }
        }
        
    }

    const handleNaira = async (e)=>{
        // alert(transferBankInput.amount)
        const {account_number,bank_code,amount} = transferBankInput;
        if(amount === "" || amount === NaN || amount === undefined) return toast.error("Amount must not be empty",{position: "top-left",pauseOnHover: true})
        if(account_number === "") return toast.error("account Number must not be empty",{position: "top-left", pauseOnHover: true})
        if(bank_code === "") return toast.error("Bank Name must not be empty",{position: "top-left", pauseOnHover: true})
        
        e.preventDefault();
        setIsNairaPayout(true);
        try{
            const headers = {
                'Authorization': `Bearer ${currentUser?.user?.merchantKey}`
            };
           
            const data = {
                amount: `${Number(transferBankInput.amount.replace(/,/g, "")) * 100}`,
                currency: transferBankInput.currency,
                bank_code: transferBankInput.bank_code,
                narration: transferBankInput.narration,
                account_number: transferBankInput.account_number,
                beneficiary_name:transferBankInput.beneficiary_name,
            }
           
          // Make an API call to fetch accountName using bankCode and accountNumber.
           
            const url = "https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/payout";
            let res = await axios.post(url,{...data},{ headers: headers});
           
            toast.success(res?.data?.message,{position: "top-left", pauseOnHover: true})
            setPayoutId(res.data?.Id)
            setPayoutResult(res?.data)
            setIsNairaPayout(false);
            setIsConfirmPortfolio(true)
            
            // setIsLoading(false)
        }catch(err){
            setIsNairaPayout(false);
            const errorMessage = err.response?.data?.message || "An error has occured"
            
            console.log(err)
            toast.error(errorMessage,{position: "top-left", pauseOnHover: true})
        }
    }
    const handleDollar = (e)=>{
        e.preventDefault();
    }

    const fetchAccountName = async () => {
        try {

            const headers = {
                api_key: currentUser?.user?.merchantKey,
                sweetapi: currentUser?.user?.merchantCode,
            };
            const data = {
                account_number:transferBankInput.account_number,
                bank_code:transferBankInput.bank_code
            }
            
          // Make an API call to fetch accountName using bankCode and accountNumber.
            const url = "https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/name-enquiry";
            let res = await axios.post(url,{...data},{ headers: headers});
            
    
          // Update the accountName in your state with the fetched data.
            
            setTransferBankInput((prevFormData) => ({
                ...prevFormData,
                beneficiary_name: res.data?.data?.account_name,
            }));
           
            setIsLoading(false)
            
        } catch (error) {
          // Handle errors, e.g., show an error message.
          setIsLoading(false)
          console.error('Error fetching accountName:', error);
        }
    };
    const handleBeneficiary = (e)=>{
        e.preventDefault()
        setIsBeneficiary(true)
    }

  return (
    <div className='balance_modal'  onClick={setIsPayout.bind(this, false)} >
        <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
          <div className='balance_modal_wrapper'>
              <img 
              onClick={setIsPayout.bind(this, false)}
              className='otc_back' src="/images/otc/backIcon.svg" alt='close' 
              />
            {/* <div className="modal-title">
                <p className="enter-amount">Make a Payout</p>
                <p className="enter-an-amount">Please provide the details of the bank for this payout.</p>
            </div> */}
            <div className="modal-title">
                <div className='step_title'>
                    <div >
                        <p className="enter-amount">Make a Payout</p>
                        <p className="enter-an-amount">Please provide the details of the bank for this payout.</p>
                    </div>
                    <img src='/images/otc/step_one.svg' alt='' />
                </div>
            </div>

                {/* <Timer /> */}
           
                {/* 
                <SelectInputDesc handleInputChange={handleInputChange} balance={transferBankInput.balance}>
                    <option value="NGN">NGN 20,9684.00</option>
                    <option value="USD">NGN 20,9684.00</option>
                </SelectInputDesc> 
                */}
                <div className='input_banks_container'>
                        <div 
                        // onClick={() => changeActive("card_num")}
                        className={`input_group ${page==="card_num" ? 'clicked' : ''}`} 
                        >
                         <select 
                            className='balance_select' 
                            style={{background:"transparent"}}
                            name='balance'
                            value={transferBankInput.balance} 
                            onChange={handleInputChange}>
                                
                                 {/* <option value="NGN">
                                    {`${balanceData[0]?.currency} ${Number(balanceData[0]?.payout_balance)/100}`}
                                </option>
                                <option value="USD"> {`${balanceData[1]?.currency} ${Number(balanceData[1]?.payout_balance)/100}`}</option> */}

                            {balanceData.map((balance, index) => (
                                <option 
                                    key={index} 
                                    value={balance.currency}
                                >
                                    {`${balance.currency} ${Number(balance.payout_balance) / 100}`}
                                </option>
                            ))}
                            </select>
                        </div>
                        <p className='card_label'>Choose balance to transfer from</p>
                </div>

                <div className='api_select_container' style={{marginTop:"16px",marginBottom:"16px"}}>
                    <div className='api_wallet_select_input'>
                        <img src={`/images/${transferBankInput.currency}.svg`} alt=""/>    
                        <select 
                            className='api_select_val' 
                            name='currency'
                            value={transferBankInput.currency} 
                            onChange={handleInputChange}
                            disabled
                        >
                           <option value={transferBankInput.balance}>{transferBankInput.balance}</option> 
                        </select>
                    </div>
                    <input  name='amount' value={transferBankInput.amount} className='api_input_amountss'  placeholder="5.00" onChange={handleInputChange} required/>

                </div> 

                {
                    transferBankInput?.currency !=="NGN" &&
                    <input className='portfolio_amount_input' placeholder='Description (optional)' name='narration' onChange={handleInputChange} style={{marginTop:"16px",marginBottom:"20px"}}/>
                }

                {
                transferBankInput?.currency !=="NGN" ?
                <form onSubmit={handleDollar}>
                    {/* <div className='bg_exchange'>
                        <p className='exch_title'>Estimated Delivery: 0 to 1 business day</p>
                        <div className="otc_exchange_rate">
                            <div className='otc_fx_values'>
                                <img src='/images/otc/exchangeLine.svg' alt='' />
                                <p className='fx_amount_desc'>Current FX Rate: </p>
                                <p className='fx_amount_text'>$1.00</p>
                                <span className='fx_amount_text'> = </span>
                                <p className='fx_amount_text'>₦1700</p>
                            </div>
                            <img src='/images/otc/otcExchangeCircle.svg' alt=''/>
                        </div>
                        <div className='info_exch'>
                            <img src='/images/otc/info.svg' alt=''/>
                            <p className='info_exh_text'>You will be charged USD 5.00 for this transaction.</p>
                        </div>
                    </div> */}


                    {/* <div className='otcform_container'>
                
                                
                                <DoubleInputGroup label1="Account Number" label2="Routing Number" handleChange={handleInputChange} name1="accountNumber" name2="routingNumber" placeholder1="22488136902667" placeholder2="23690867"/>
                                
                                <SingleInputGroup label="Beneficiary Account Name" handleChange={handleInputChange} name="beneficiaryName" placeholder="Ayomide Ajibade"/>
                                <BusinessAddress label="Beneficiary Address" />
                                <div style={{display:"flex",flexDirection:"column",marginTop:"20px"}}>
                                    <DoubleInputGroup label1="Address Line 1" label2="Address Line 2" handleChange={handleInputChange} name1="address1" name2="address2" />
                                    <DoubleInputGroup label1="City" label2="State" handleChange={handleInputChange} name1="city" name2="state" />
                                    <DoubleInputGroup select label1="Country" label2="Postal Code" handleChange={handleInputChange} name1="city" name2="state">
                                        <option value="" disabled>USA</option>
                                        <option value="USD">USA</option>
                                    </DoubleInputGroup>
                                </div>

                    

                        
                    </div> */}

                    <DropDownBeneficiary setSelectedOption={setSelectedBeneficiary} setIsConfirmBankDetial={setIsConfirmBankDetial} setIsBenAdd={setIsBenAdd} addBeneficiary={addBeneficiary} beneficiaryData={beneficiaryData} transferBankInput={transferBankInput}/>

                    
                
                </form>:
                <form  onSubmit={handleNaira}>
                    <div className='api_wallet_input'>
                        <select 
                        required
                        className='portfolio_amount_input' 
                        name='bank_code'
                        onChange={handleInputChange}
                        style={{marginTop:"16px"}}>
                            <option value="" selected disabled>Bank name</option>
                            {
                                banks.map((bank,index)=>(
                                    <option key={index} value={bank?.bank_code}>{bank?.bank_name}</option>
                                ))
                            }
                        
                        </select>
                        <p className='select_exist' style={{cursor:"pointer"}} onClick={handleBeneficiary}>Or select existing beneficiary  <img style={{display:"inline",marginLeft:"8px"}} src='/images/selectBeneficiary.svg' alt='ben'/></p>       
                        <input className='portfolio_amount_input' placeholder='Account number' name='account_number' onChange={handleInputChange} style={{marginTop:"16px"}} required/>
                        <div className='account_loader'>
                            {
                                isLoading ?
                                <div className='loader_overlay'>  
                                <CircleLoader
                                    color="#259792"
                                    size={15}
                                />
                                </div>:
                                <input value={transferBankInput.beneficiary_name} className='portfolio_amount_input' placeholder='Account name' name='beneficiary_name' onChange={handleInputChange} style={{marginTop:"16px"}} readOnly/>
                            }
                        </div>
                        <input className='portfolio_amount_input' placeholder='Description (optional)' name='narration' onChange={handleInputChange} style={{marginTop:"16px"}}/>
                        <div className="modal-btn" style={{display:"flex", gap:"12px", marginTop:"32px"}}>
                        <button onClick={setIsPayout.bind(this,false)} className="btn otc_btn_cancel">
                            Cancel
                        </button>
                        {
                            !isNairPayout ?
                        <button  className="btn" onClick={handleNaira}>   
                            Confirm transfer
                        </button>:
                        <p className="btn">
                            <BeatLoader
                                color="#FFF"
                                cssOverride={{}}
                            />
                        </p>
                        }

                    </div>
                    </div>
                </form>
                }
            
          </div>
        </div>
    </div>
  )
}

export default PayoutModal