import React from 'react'

function DoubleInputGroup({label1,label2,name1,name2,handleChange,select,doubleSelect,placeholder1,placeholder2,children,children2,readOnly,value}) {
  return (
    <div className='input_group_otc_double'>
        <div className='input_group_double'>
            <p className='input_text'>{label1}</p>
            {
                select ?  <select 
                className='half_input' 
                name={name1}
                onChange={handleChange}
                
                >
                    {children}
        
                </select> :

                <input className='half_input' name={name1} onChange={handleChange} placeholder={placeholder1} readOnly={readOnly} value={value}/>
            }
        </div>
        {
                doubleSelect ?

                <select 
                className='half_input' 
                name={name2}
                onChange={handleChange}
                
                >
                    {children2}
        
                </select> :

                <div className='input_group_double'>
                    <p className='input_text'>{label2}</p>
                    <input className='half_input'  name={name2} onChange={handleChange} placeholder={placeholder2} />
                </div>
        }
    </div>
  )

}
export default DoubleInputGroup