import React, { useState } from 'react'
import './tables.scss'
import styled from 'styled-components'
import tblhamburg from '../../assets/tblhamburg.svg'
import Status from './Status'
import { useNavigate } from 'react-router-dom'
import SelectCurrency from './SelectCurrency'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range'

import { format } from 'date-fns'


const DateInput =styled.input`
&[type="date"]::-webkit-calendar-picker-indicator {
    content: url(${tblhamburg});
    width: 32px;
    height: 32px;
  }

  &[type='date']:before {
    content: attr(placeholder);
  }

  &::-webkit-datetime-edit {
    display: none;
  }

  &[type='date'] {
    outline: none;
    border: none;
  }
`

const DirectDebitOptions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 12px 14px 12px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--Component-colors-Utility-Gray-utility-gray-100, #F2F4F7);
  background: #FFF;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.06);
  position: absolute;
  z-index: 6;
  width: 219px;
  margin-top: 50px;

  .option {
    display: flex;
    padding: 3px 8px;
    align-items: center;
    width: 100%;
    gap: 4px;
    color: #333;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px; 
    border-radius: 6px;
    cursor: pointer;

    &:hover,
    &:active {
      background: #F3F3F3;
      color: #000;
      font-size: 14px;
      font-weight: 450;
      line-height: 20px;
    }
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 5;
`
function TableHeaderSearch({title,placeholder,payout,toggleTransfer,users,numUsers,btnFirst,link,paylink,toggleLink,linkProfile,sync,syncPath,stats,handleOptionClick,setSelectedValue,selectedValue,otc,inflowCustomer,getCollection,selectedCurrency,setSelectedCurrency,directDebit,direct,overview,balanceData,searchTerm, setSearchTerm, dateRange, handleDateChange}) {
  const navigate = useNavigate()
  const [showDirectDebitOptions, setShowDirectDebitOptions] = useState(false)


  const [date,setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  })
  const [isDateOpen,setIsDateOpen] = useState(false);

  const handleTransfer =()=>{
    toggleTransfer();
  }

  const handleInputDateChange = (e) => {
    const value = e.target.value;
    
    // Perform any validation or checks here
    if (setSearchTerm) {
        setSearchTerm(value);
    }
};


  const handleLink = ()=>{
    toggleLink();
  }

  const handleTransaction =()=>{
    navigate(syncPath)
  }

  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value)
    setSelectedCurrency(e.target.value)
    getCollection(e.target.value)
    
  };
  const toggleDirectDebitOptions = () => {
    setShowDirectDebitOptions(!showDirectDebitOptions)
  }
  const toggleDate = ()=>{
    if(isDateOpen){

      setIsDateOpen(!isDateOpen);
    }
  }

  const formattedStartDate = dateRange?.startDate ? format(new Date(dateRange?.startDate), 'dd MMMM') : '';
  const formattedEndDate = dateRange?.endDate ? format(new Date(dateRange?.endDate), 'dd MMMM') : '';

  return (
    <div className='tbl-search custom-subheader'>
        <div className='tbl-search-title-content'>
            {
              otc ?
              <div style={{display:"flex",alignItems:"center",gap:"20px"}}>

              <h1 className='tbl-search-title'>{title}</h1>
              <SelectCurrency selectedValue={selectedCurrency} handleSelectChange={handleSelectChange} ml="25px" >
                {
                  balanceData?.length > 0 ? 
                  balanceData.map((item)=>(
                    <option value={item.currency}>{item.currency}</option>
                  )) :
                  <>
                  
                  <option value='NGN'>NGN</option> 
                  <option value='USD'>USD</option> 
                  <option value='CAD'>CAD</option> 
                  <option value='GBP'>GBP</option> 
                  <option value='EUR'>EUR</option> 
                  </>
                }
 
              </SelectCurrency>
              </div>
              :inflowCustomer?
              <div style={{display:"flex",alignItems:"center",gap:"20px"}}>

              <h1 className='tbl-search-title'>{title}</h1>
              <SelectCurrency selectedValue={selectedValue} handleSelectChange={handleSelectChange} ml="25px" >
              {
                  balanceData?.length > 0 ? 
                  balanceData.map((item)=>(
                    <option value={item.currency}>{item.currency}</option>
                  )) :
                  <>
                  
                  <option value='NGN'>NGN</option> 
                  <option value='USD'>USD</option> 
                  <option value='CAD'>CAD</option> 
                  <option value='GBP'>GBP</option> 
                  <option value='EUR'>EUR</option> 
                  </>
                }
               
              </SelectCurrency>
              </div>:overview?
              <div style={{display:"flex",alignItems:"center",gap:"20px"}}>

                <h1 className='tbl-search-title'>{title}</h1>
                <div className='all-balancey-currency'>
                <img src={`/images/${selectedCurrency === undefined ? "NGN" : selectedCurrency }.svg`} alt='icon' />

                <select value={selectedCurrency} onChange={handleSelectChange} className='curreny-option'>
                  {/* <option value='NGN'>NGN</option>
                  <option value='USD'>USD</option> */}
                  {
                    balanceData?.length > 0 ? 
                    balanceData.map((item)=>(
                      <option value={item.currency}>{item.currency}</option>
                    )) :
                    <>
                    
                    <option value='NGN'>NGN</option> 
                    <option value='USD'>USD</option> 
                    <option value='CAD'>CAD</option> 
                    <option value='GBP'>GBP</option> 
                    <option value='EUR'>EUR</option> 
                    </>
                  }
                </select>

                </div>
              </div>:
              <h1 className='tbl-search-title'>
                {directDebit ? <span className='styled-title' onClick={toggleDirectDebitOptions}>{title} <img src={process.env.PUBLIC_URL+"/images/mandate-down.svg"} alt="arr"/></span> : title} 
                {users && btnFirst && <span>
                  <Status num={numUsers} mgs={users} />
                  </span>
                }
                {link &&
                <button onClick={handleLink} className='btn' style={{padding:"8px 14px",font:"12px",width:"fit-content",borderRadius:"border-radius: 8px",marginLeft:"24px"}}>
                  Create a statement link  <img src="/images/plusLink.svg" alt="plus" style={{display:"inline",marginLeft:"8px"}}/>
                  </button>
                  }
                  {paylink &&
                    <button onClick={handleLink} className='btn' style={{padding:"8px 14px",font:"12px",width:"fit-content",borderRadius:"border-radius: 8px",marginLeft:"24px"}}>
                      Create a payment link  <img src="/images/plusLink.svg" alt="plus" style={{display:"inline",marginLeft:"8px"}}/>
                    </button>
                  }
                  {direct &&
                    <button onClick={handleLink} className='btn' style={{padding:"8px 14px",font:"12px",width:"fit-content",borderRadius:"border-radius: 8px",marginLeft:"24px"}}>
                      Create a direct-debit link  <img src="/images/plusLink.svg" alt="plus" style={{display:"inline",marginLeft:"8px"}}/>
                    </button>
                  }
              </h1>
            }

            {
                payout &&
                <div onClick={handleTransfer} className='new_transfer_payout' >
                    <span>New Transfers</span>
                    <img src='/images/plusTransfer.svg' alt='plus' />
                </div>
            }
            {showDirectDebitOptions && (
                 <>
                 <DirectDebitOptions>
                 <div className='option' onClick={() => {handleOptionClick('All Mandates'); toggleDirectDebitOptions();}}>All Mandates</div>
                 <div className='option' onClick={() => {handleOptionClick('Active Mandates'); toggleDirectDebitOptions();}}>Active Mandates</div>
                 <div className='option' onClick={() => {handleOptionClick('Expired Mandates'); toggleDirectDebitOptions();}}>Expired Mandates</div>
                 <div className='option' onClick={() => {handleOptionClick('Pending Mandates'); toggleDirectDebitOptions();}}>Pending Mandates</div>
                 <div className='option' onClick={() => {handleOptionClick('Deactivated Mandates'); toggleDirectDebitOptions();}}>Deactivated Mandates</div>

                 </DirectDebitOptions>
                 <Overlay onClick={toggleDirectDebitOptions} />
               </>
            )}
        </div>
        
        <div className='tbl-search-right'>
          {
            !linkProfile ?
            <div className='tbl-date'>
                <input 
                className='input-search' 
                type='text' 
                placeholder={placeholder}
                value={searchTerm}
                onChange={handleInputDateChange}
                />
                <img src={process.env.PUBLIC_URL+"/images/searchIcon.svg"} alt="search"/>
            </div> :  !sync?
            <img src='/images/searchIconSmall.svg' alt='search'/>:""
          }
          {
            !sync?
            <>
            <div className='tbl-months'>  
                <img className='image-from' src={process.env.PUBLIC_URL+"/images/tblTime.svg"} alt="search"/>
                <div className='tbl-data-time'>
                    <p className='date-from'>From:</p>
                    <h1 className='date-to'>{formattedStartDate}</h1>
                    
                </div>

                <div className='date-delimiter'></div>

                <div className='tbl-data-time'>
                    <p className='date-from'>To:</p>
                    <h1 className='date-to'>{formattedEndDate}</h1>
                    
                </div>
            </div>
            <img src='/images/datePicker.svg' alt='' onClick={toggleDate} />
            {/* <label htmlFor="date_pick" className='lbl-cv' style={{display:"flex", alignItems:"center"}}>
               
                <DateInput type="date" name="date_pick" />
            </label> */}
            </>: 
            <div className={`syn_backlist ${stats? "off_sync":""}`}>
              <img onClick={handleTransaction} style={{cursor:"pointer"}}  src={process.env.PUBLIC_URL+"/images/SyncBadge.svg"} alt="verified" />
              <img onClick={handleTransaction} style={{cursor:"pointer"}}  src={process.env.PUBLIC_URL+"/images/blackList.svg"} alt="verified" />
            </div>

            
          }

          {
            isDateOpen &&
          <DateRangePicker 
            className='date_picker_object'
            ranges={[dateRange]}
            onChange={handleDateChange}

           
          />
          }

          
           
        </div>
    </div>
  )
}

export default TableHeaderSearch
