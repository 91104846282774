import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import FintechGetStarted from "./pages/FintechGetStarted";
import OverView from "./pages/OverView";
import ForgotPassword from "./pages/ForgotPassword";
import CheckEmail from "./pages/CheckEmail";
import SetNewPassword from "./pages/SetNewPassword";
import PasswordReset from "./pages/PasswordReset";
import Balance from "./pages/Balance";
import PaymentTransaction from "./pages/PaymentTransaction";
import PaymentSettlement from "./pages/PaymentSettlement";
import PaymentCustomers from "./pages/PaymentCustomers";
import PaymentCustomer from "./pages/PaymentCustomer";
import DataIdentity from "./pages/DataIdentity";
import DataAccountBalance from "./pages/DataAccountBalance";
import DataTransactions from "./pages/DataTransactions";
import DataTransaction from "./pages/DataTransaction";
import DataIncome from "./pages/DataIncome";
import DataIncomes from "./pages/DataIncomes";
import DataStatements from "./pages/DataStatements";
import DataCustomers from "./pages/DataCustomers";
import DataIdentityDetail from "./pages/DataIdentityDetail";
import GetStartedTest from "./pages/GetStartedTest";
import ProtectedRoute from "./components/protectedRoute/ProtectedRoute";
import Layout from "./components/layout/Layout";
import ContactUs from "./pages/ContactUs";
import SettingsPage from "./pages/SettingsPage";
import EmailVerification from "./pages/EmailVerification";
import Landing from "./pages/Landing";
import DataCustomerProfile from "./pages/DataCustomerProfile";
import DataLinkPage from "./pages/DataLinkPage";
import LinkRegister from "./pages/dataExternalPage/LinkRegister";
import LinkAccount from "./pages/dataExternalPage/LinkAccount";
import ChooseBank from "./pages/dataExternalPage/ChooseBank";
import LoginMethod from "./pages/dataExternalPage/LoginMethod";
import LoginPersonalDetails from "./pages/dataExternalPage/LoginPersonalDetails";
import LoginChooseAccount from "./pages/dataExternalPage/LoginChooseAccount";
import LoginSuccessful from "./pages/dataExternalPage/LoginSuccessful";
import AccountShared from "./pages/dataExternalPage/AccountShared";
import DataCustomer from "./pages/DataCustomer";
import SignUpPage from "./pages/SignUpPage";
import LoginPage from "./pages/LoginPage";
import LoginDetails from "./components/data/connectAccounts/LoginDetails";
import ApiWalletHistory from "./pages/ApiWalletHistory";
import axios from "axios";
import { useSelector } from "react-redux";
import DisputePage from "./pages/DisputePage";
import PayLinksPage from "./pages/PayLinksPage";
import TestModeData from "./components/data/TestModeData";
import TestModePayment from "./components/payment/TestModePayment";
import Reccurring from "./components/payment/Reccurring";
import { formatCustomersData } from "./components/utilities/formateData";
import SignUpSuccessPage from "./pages/SignUpSuccessPage";
import KycSuccess from "./pages/KycSuccess";
import SwitchLivePage from "./pages/SwitchLivePage";
import SignedAgreement from "./pages/SignedAgreement";
import NewBusinessPage from "./pages/NewBusinessPage";
import ExistingKyc from "./pages/ExistingKyc";
import SwitChBussiness from "./pages/SwitChBussiness";
import SwitChNewBussiness from "./pages/SwitchNewBusiness";
import OtcDashboard from "./pages/otcDashboard/OtcDashboard";
import CustomerWebLink from "./pages/otcDashboard/CustomerWebLink";
import BeneficiaryDetails from "./pages/otcDashboard/BeneficiaryDetails";
import BankDetails from "./pages/otcDashboard/BankDetails";
import PaymentSubmitted from "./pages/otcDashboard/PaymentSubmitted";
import ConfirmDetails from "./pages/otcDashboard/ConfirmDetails";
import TransactionInflow from "./pages/transactions/TransactionInflow";
import TransactionOutflow from "./pages/transactions/TransactionOutflow";
import DirectDebitPage from "./pages/DirectDebitPage";
import DirectDebitDetails from "./components/payment/DirectDebitDetails";
import AcceptTeamMember from "./components/settings/teamPermissions/AcceptTeamMember";

function App() {
  const { currentUser } = useSelector((state) => state?.user);
  const [balanceData, setBalanceData] = useState([]);
  const [collectionData, setCollectionData] = useState([]);
  const [settlementData, setSettlementData] = useState([]);
  const [disputesData, setDisputesData] = useState([]);
  const [refundData, setRefundData] = useState([]);
  const [payLink, setPayLink] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [graphCurrency, setGraphCurrency] = useState("");
  const [walletApi, setWalletApi] = useState([]);
  const [customersData, setCustomersData] = useState({});
  const [allCustomerData, setAllCustomerData] = useState([]);
  const [accountData, setAccountData] = useState([]);
  const [isAllCustomerLoading, setIsAllCustomerLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState('NGN'); // Initialize with an empty string or default value

  // const [selectedCurrency,setSelectedCurrency] = useState("NGN");

  useEffect(() => {
    getBalance();
    getGraph();
    getWalletapi();
    getCustomers();
    getAllCustomers();
    // getAccounts();
    // eslint-disable-next-line
  }, []);

  // formatData
  const formatData = async (data) => {
    const formattedData = [];

    // Loop through the values of the "Cus_account" object
    for (const accountData of Object.values(data.Cus_account)) {
      for (const item of accountData) {
        const formattedObject = {
          Nuban: item.Nuban,
          currency: item?.currency,
          BankName: item?.BankName,
          bvn: item?.bvn,
          CustomerCode: item?.CustomerCode,
          fullName: item?.fullName || "",
          transactions: {
            totalDebit: item?.transactions.totalDebit,
            totalCredit: item?.transactions.totalCredit,
            totalTransaction: item?.transactions.totalTransaction,
            Nuban: item?.Nuban,
          },
        };
        formattedData.push(formattedObject);
      }
    }

    return formattedData;
  };

  // get balance
  const getBalance = async () => {
    try {
      const headers = {
        api_key: currentUser.user.merchantKey,
        sweetapi: currentUser.user.merchantCode,
      };
      const url =
        "https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/balances?IsDash=1";
      let res = await axios.get(url, {
        headers: headers,
      });

      const newValue = res.data.data;

      

      setBalanceData(newValue || []);
    } catch (err) {
      
      const errorMessage = err?.message || "unable to fetch data";
    }
  };

  const getWalletapi = async () => {
    try {
      const headers = {
        api_key: currentUser?.user?.merchantKey,
        sweetapi: currentUser?.user?.merchantCode,
      };
      const data = {
        category: "walletapi", //required
        currency: "NGN", // required
      };
      const url =
        "https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/transactions";
      let res = await axios.post(
        url,
        { ...data },
        {
          headers: headers,
        }
      );

      const newValue = res?.data?.data;

      setWalletApi(newValue);
      // toast.success(res.data.message);
    } catch (err) {
      
      // const errorMessage = err?.response?.data?.data?.message || "Server error"
    }
  };

  // get graph

  const getGraph = async (currency="NGN") => {
    try {

      const headers = {
        "api_key": currentUser?.user?.merchantKey,
        "sweetapi": currentUser?.user?.merchantCode,
      };

      const data = {
        currency: currency,
      };

      const url =
        "https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/transaction-graph";
      let res = await axios.post(
        url,
        { ...data },
        {
          headers: headers,
        }
      );

      const newValue = res?.data?.data;
      
      setGraphData(newValue);
    } catch (err) {
     
      const errorMessage = err?.response?.data?.data?.message || "Server error";
    }
  };

  // get customer data
  const getCustomers = async () => {
    try {
      const headers = {
        api_key: currentUser.user.merchantKey,
        sweetapi: currentUser.user.merchantCode,
      };
      const url =
        "https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/get-merchant-customers";
      let res = await axios.get(url, {
        headers: headers,
      });

      const data = res?.data?.data;

      setCustomersData(data);
      // setBalanceData(newValue || []);
    } catch (err) {
      
      const errorMessage = err?.message || "unable to fetch data";
    }
  };



  const getAllCustomers = async () => {
    setIsAllCustomerLoading(true);
    try {
      const headers = {
        api_key: currentUser.user.merchantKey,
        sweetapi: currentUser.user.merchantCode,
      };
      const url =
        "https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/get-merchant-customers";
      let res = await axios.get(url, {
        headers: headers,
      });

      const data = res?.data?.data;

      
      const result = await formatCustomersData(data);

      setAllCustomerData(result);
      setIsAllCustomerLoading(false);
      // setBalanceData(newValue || []);
    } catch (err) {
      setIsAllCustomerLoading(false);
      
      const errorMessage = err?.message || "unable to fetch data";
    }
  };

  
  return (
    <Router>
      <Routes>
        <Route path="/customer-link" element={<CustomerWebLink  />}/>
       
        <Route path="/beneficiary-details" element={<BeneficiaryDetails  />}/>
        <Route path="/bank-details" element={<ConfirmDetails  />}/>
        <Route path="/confirm-details" element={<BankDetails  />}/>
        <Route path="/payment-submitted" element={<PaymentSubmitted  />}/>
        <Route path="/business" element={<Landing />} />
        <Route path="/confirm-agreement" element={<SwitchLivePage />} />
        <Route path="/switch-business" element={<SwitChBussiness />} />
        <Route path="/switch-new-business" element={<SwitChNewBussiness />} />

        <Route path="/link-details" element={<LinkRegister />} />
        <Route path="/link-account" element={<LinkAccount />} />
        <Route path="/choose-account" element={<ChooseBank />} />
        <Route path="/login-method" element={<LoginMethod />} />
        <Route path="/login-personalInfo" element={<LoginPersonalDetails />} />
        <Route path="/login-choose-account" element={<LoginChooseAccount />} />
        <Route path="/login-successful" element={<LoginSuccessful />} />
        <Route path="/link-success" element={<AccountShared />} />
        <Route path="/signUp" element={<SignUpPage />} />
        <Route path="/" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/check-email" element={<CheckEmail />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/accept-team-member/:encryptedTeamId" element={<AcceptTeamMember />} />
        <Route path="/verify-email/:id" element={<EmailVerification />} />
        <Route path="/reset-password/:id" element={<SetNewPassword />} />
        <Route path="/sign-up-successful" element={<SignUpSuccessPage />} />
        <Route path="/agreement" element={<SignedAgreement />} />
        <Route path="/kyc-success" element={<KycSuccess />} />
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="/connect-account" element={<LoginDetails />} />
          {/* <Route path="/accept-team-member" element={<AcceptTeamMember />} /> */}
          <Route element={<Layout />}>
            <Route path="getstarted" element={<GetStartedTest />} />
            <Route path="complete-kyc" element={<FintechGetStarted />} />
            <Route
              path="overview"
              element={
                <OverView
                  collectionData={collectionData}
                  balanceData={balanceData}
                  graphData={graphData}
                  graphCurrency={graphCurrency}
                  setGraphCurrency={setGraphCurrency}
                  setBalanceData={setBalanceData}
                  setSelectedValue={setSelectedValue}
                  selectedValue={selectedValue}
                  getGraph={getGraph}
                />
              }
            />
            <Route
              path="balance"
              element={<Balance balanceData={balanceData} />}
            />
            <Route
              path="payment-collections"
              element={<PaymentTransaction />}
            />
            <Route
              path="direct-debit"
              element={<DirectDebitPage />}
            />
            <Route
              path="direct-debit-details/:transactionId"
              element={<DirectDebitDetails />}
            />
            <Route
              path="payment-payout"
              element={<PaymentSettlement balanceData={balanceData} />}
            />
            <Route
              path="/payment-dispute"
              element={
                <DisputePage
                  disputesData={disputesData}
                  refundData={refundData}
                />
              }
            />
            <Route path="payment-customers" element={<PaymentCustomers />} />
            <Route path="payment-customer" element={<PaymentCustomer />} />
            <Route path="data-identity" element={<DataIdentity />} />
            <Route
              path="data-identity-detial"
              element={<DataIdentityDetail />}
            />
            <Route path="data-account" element={<DataAccountBalance />} />
            <Route path="data-transactions" element={<DataTransactions />} />
            <Route path="data-transaction" element={<DataTransaction />} />
            <Route path="data-profile" element={<DataCustomerProfile />} />
            <Route path="data-incomes" element={<DataIncomes />} />
            <Route path="data-income" element={<DataIncome />} />
            <Route path="data-statements" element={<DataStatements />} />
            <Route path="data-statement" element={<DataLinkPage />} />
            <Route
              path="data-customers"
              element={
                <DataCustomers
                  isAllCustomerLoading={isAllCustomerLoading}
                  customersData={customersData}
                  allCustomerData={allCustomerData}
                />
              }
            />
            <Route
              path="payment-recurring"
              element={<PayLinksPage payLink={payLink} />}
            />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/new-business" element={<NewBusinessPage />} />
            <Route path="/business-document" element={<ExistingKyc />} />
            <Route path="/test-mode-payment" element={<TestModePayment />} />
            <Route path="/test-mode-data" element={<TestModeData />} />
            <Route path="/data-customer" element={<DataCustomer />} />
            <Route
              path="/wallet-history"
              element={<ApiWalletHistory collectionData={collectionData} />}
            />

            <Route
              path="/otc-dashboard"
              element={<OtcDashboard  />}
            />
            <Route
              path="/transaction-inflow"
              element={<TransactionInflow  />}
            />
            <Route
              path="/transaction-outflow"
              element={<TransactionOutflow  />}
            />

            
            {/* data external link */}
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;


