import React, { useEffect, useState } from 'react'
import SelectInputGroup from './SelectInputGroup';
import DoubleInputGroup from './DoubleInputGroup';
import SingleInputGroup from './SingleInputGroup';
import BusinessAddress from './BusinessAddress';
import { BeatLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import axios from 'axios';

function BankDetailModal({setIsBankDetail,BankDetailInput,setBankDetailInput,setIsConfirmBankDetial,setIsPayout,setIsBenAdd,setBeneficiaryInput,addBeneficiary,isAddBeneficiary,beneficiaryInput,countries,setIsPayoutBenSubmited}) {

    
    const  handleInputChange = (event)=>{
        const { name, value } = event.target;
        setBeneficiaryInput((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));  
    }




    const handleSubmit = async (e)=>{
        e.preventDefault();
        
        const {bank_name,bank_address_1,bank_address_2,bank_city,bank_state,bank_country,bank_postal_code} = beneficiaryInput
        if(bank_name === "") return toast.error("Bank Name must not be empty",{position: "top-left", pauseOnHover: true})
        if(bank_address_1 === "") return toast.error("Address one must not be empty",{position: "top-left", pauseOnHover: true})
        if(bank_city === "") return toast.error("city must not be empty",{position: "top-left", pauseOnHover: true})
        if(bank_state === "") return toast.error("State must not be empty",{position: "top-left", pauseOnHover: true})
        if(bank_country === "") return toast.error("Country must not be empty",{position: "top-left", pauseOnHover: true})
        if(bank_postal_code === "") return toast.error("Bank Postal code must not be empty",{position: "top-left", pauseOnHover: true})
        // if(bank_address_2 === "") return toast.error("Address two must not be empty",{position: "top-left", pauseOnHover: true})
       try{
           await addBeneficiary();
          
         
           if(addBeneficiary){
               setIsPayoutBenSubmited(true);
           }

       }catch(err){
            console.log(err)
       }
        

      
       

    }
    const handleClose = ()=>{
        setIsBankDetail(false)
        setIsBenAdd(false);
        setIsPayout(false);

    }
  return (
    <div className='balance_modal'  onClick={setIsBankDetail.bind(this, false)} >
        <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
        <div className='balance_modal_wrapper'>
            <img 
            onClick={setIsBankDetail.bind(this, false)}
            className='otc_back' src="/images/otc/backIcon.svg" alt='close' 
            />

            <div className="modal-title">
                <div className='step_title'>
                    <div >
                        <p className="enter-amount">Bank Details</p>
                        {/* <p className="enter-an-amount">Please enter the amount above $5,000</p> */}
                    </div>
                    <img src='/images/otc/step_two.svg' alt='' />
                </div>
            </div>
            

        

            <div className='otcform_container'>
            <form onSubmit={handleSubmit} >
                        <div>
                        <p className='input_text'>Bank Name</p>
                         <input className='otc_select_full' style={{width:"100%"}}  name="bank_name" onChange={handleInputChange} />
                        </div>
                       
                        <BusinessAddress label="Bank Address" />

                        <div style={{display:"flex",flexDirection:"column",marginTop:"20px"}}>
                            <DoubleInputGroup label1="Address Line 1" label2="Address Line 2" handleChange={handleInputChange} name1="bank_address_1" name2="bank_address_2" />
                            <DoubleInputGroup label1="City" label2="State" handleChange={handleInputChange} name1="bank_city" name2="bank_state" />
                            <DoubleInputGroup select label1="Country" label2="Postal Code" handleChange={handleInputChange} name1="bank_country" name2="bank_postal_code">
                                <option value="USA">United States</option>
                                <option value="United Kingdom">United Kingdom</option>
                                <option value="China">China</option>
                                {
                                    countries.map((country)=>(
                                        <option value={country.name}>{country.name}</option>
                                    ))
                                }
                               
                            </DoubleInputGroup>
                        </div>

            </form>

                
            </div>

            <div className='otc_btn_modal'>
                <button className='btn otc_btn_cancel' onClick={handleClose} >
                    Cancel
                </button>
                
                {
                    isAddBeneficiary ?
                <button className='btn' >
                    <BeatLoader
                        color="#FFF"
                        cssOverride={{}}
                    />
                </button>:
                <button className='btn' onClick={handleSubmit} >
                    Submit
                </button>
                }
            </div>
        </div>
        </div>
    </div>
  )
}

export default BankDetailModal