import React, { useEffect,useRef, useState,createRef } from 'react'
import BusinessOverview from '../businessOverview/BusinessOverview'
import './GetStarted.scss'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { toast } from 'react-toastify';
import Progress from '../utilities/Progress';
import { loginSuccess, logout } from '../../redux/userSlice';

function IntroduceYourBusiness() {
  const navigate = useNavigate()
  const handleBack = ()=>{
    navigate('/getstarted')
  }
  useEffect(()=>{
    loadCountries();
    getFormDetails();
    // eslint-disable-next-line
  },[])
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const {currentUser} = useSelector((state)=>state?.user)
  const [isLoading, setIsLoading] = useState(false);
  const [isSocial, setIsSocial] = useState(false);
  const [isDir, setIsDir] = useState(false);
  const [isDoc, setIsDoc] = useState(false);
  const [isharehold,setIsharehold] =useState(false);
  const [isUtility,setIsUtility] =useState(false);
  const [isFormSubLoading, setIsFormSubLoading] = useState(false)
  const [isDirChange, setIsDirChange] = useState(false)
  const [isShareChange, setIsShareChange] = useState(false)
  


  const inputRef = useRef();
  const inCertRef = useRef();
  const artAssocRef = useRef();
  const utilityRef = useRef();
  const addressRef = useRef();
  const shareholdRef = useRef();
  const testRef = useRef();
  const coRef = useRef();
  const coSevenRef = useRef();
  const lineRefs = React.useRef([]);

  // const inputRefs = useRef([]);
  // const addressRefs = useRef([]);

 

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [opUploadPercentage, setOpUploadPercentage] = useState(0);
  const [asUploadPercentage, setAsUploadPercentage] = useState(0);
  const [dirPercentage, setDirPercentage] = useState(0);
  const [sharePercentage, setSharePercentage] = useState(0);
  const [coPercentage,setCoPercentage] = useState(0);
  const [coSevenPercentage,setCoSevenPercentage] = useState(0);
  
  const [additionPercentage,setAdditionPercentage] = useState(0);



  const [addressFile,setAddressFile] = useState(null)
  const [DirectorID, setDirectorID] = useState(null);
  const [directorAddressID, setDirectorAddressID] = useState(null);
  const [shareholderID, setShareholderID] = useState(null);
  const [IncCertificate, setInCertificate] = useState(null);
  const [IncCertificateFile, setInCertificateFile] = useState(null);
 

  const [articleOfAssoc, setArticleAssoc] = useState(null);
  const [articleAssocfile, setArticleAssocFile] = useState(null);
  const [opBusUtilBill, setUtilityBill] = useState(null);
  const [opBusUtilBillFile, setUtilityBillFile] = useState(null);
  const [co,setCo] = useState(null);
  const [coFile,setCoFile] = useState(null);
  const [coSeven,setCoSeven] = useState(null);
  const [coSevenFile,setCoSevenFile] = useState(null)
  const [addition,setAddition] = useState(null);
  const [additionFile,setAdditionFile] = useState(null);
  
  const [Dpreview, setDPreview] = useState()
  const [shareholderPreview, setShareholderPreview] = useState()
  const [shareholder,setShareholder] = useState('natural')
  const [businessInfo, setBusinessInfo] = useState({
    business_name:"", business_model:"", doInc:"",tin:"",zipCode:"",city:"",company_type:"",incNumber:"",coInc:"",address:"",company_state:"",biz_website:""}
  )
  const [conBio,setConBio] = useState('')
  const [socialBuss,setSocialBuss] = useState({
    website:"",
    twitter:"",
    linkedIn:"",
    instagram:""
  })
  
  const [directorInputs, setDirectorInputs] = useState([
    { firstName: '', lastName: '',position:"",dob:"",idDoc:"",bvn:"",idNumber:"",issuedCountry:"",resAddress:"", validId: '', proofOfAddress: ''}, // Use null for file inputs
  ]);

  const [shareholderInput, setShareholderInput] = useState([
    { firstName: '', lastName: '',percOfShares:"",nationality:"",naturalPerson:true,legalEntity:false}, // Use null for file inputs
  ]);
  const [legalEntity, setLegalEntity] = useState([
    {entity_name: '', country_of_Inc: '',Inc_Number:"",percOfShares:"",naturalPerson:false,legalEntity:true}, // Use null for file inputs
  ]);

  const [pep,setPep] = useState([
    {fullName:"",position:"",resAddress:""},
  ]);

  const [additionDoc,setAdditionDoc] = useState([
    {doc:""},
  ]);  


  lineRefs.current = additionDoc?.map((_, i) => lineRefs.current[i] ?? createRef());
  // Initialize Refs Dynamically
  const inputRefs = useRef([]);
  const addressRefs = useRef([]);

  inputRefs.current = directorInputs.map((_, i) => inputRefs.current[i] ?? React.createRef());
  addressRefs.current = directorInputs.map((_, i) => addressRefs.current[i] ?? React.createRef());
  
  
  
  const [payIn,setPayIn] = useState({
    virtual_accounts:false,
    checkout_card:false,
    checkout_bank:false,
    multi_currency:false
  });
  const [payOut,setPayOut] = useState({
    local_pay_outs:false,
    international_pay_outs:false,
  });
  
  const [agency,setAgency] = useState({
    transaction:false,
    statement:false,
    balance:false,
    credit_report:false
  });
  const [payment,setPayment] = useState({
    kyc:false,
    kyb:false,
    widget:false,
    api:false
  });

  const [conversion,setConversion] = useState({
    nigeria:false,
    ghana:false,
    kenya:false,
    southAfrica:false
  });


  // shareholder Natural
  const handleNaturalChange = (event,index)=>{
    const { name, value } = event.target;
    let data = [...shareholderInput];
    data[index][name] = value;
    setShareholderInput(data);
  }

  const addNaturalShare = ()=>{
    let object = { firstName: '', lastName: '',percOfShares:"",dob:"",nationality:"",idDoc:"",idNumber:"",issuedCountry:"",resAddress:"", shareholdersValidId: null,naturalPerson:true,legalEntity:false}
    setShareholderInput([...shareholderInput,object])
  }

  const removeNaturalShare = (index)=>{
    let data = [...shareholderInput];
    data.splice(index,1)
    setShareholderInput(data);
  }

  const cont = async (code)=>{
    return countries.find(country => country.iso2 === code);
  }
  const stat = async (code)=>{
    return states.find(state => state.iso2 === code);
  }
  
  const handleCountryChange = async (e) => {
    const selectedCountryCode = e.target.value;
    // const selectedCountry = countries.find(country => country.iso2 === selectedCountryCode);
    const selectedCountry = await cont(selectedCountryCode)
    setConBio(selectedCountry?.name);
    setBusinessInfo(prevState => ({
      ...prevState,
      coInc:  selectedCountry?.name 
    }))
    setSelectedCountry(selectedCountryCode);
    loadStates(selectedCountryCode);
    setStates([]); // Clear existing states
    setCities([]); // Clear existing cities
  };

  const handleStateChange = async (e) => {
    const selectedStateCode = e.target.value;
    const selectedState = await stat(selectedStateCode)
    setBusinessInfo(prevState => ({
      ...prevState,
      company_state:selectedState?.name 
    }));
    loadCities(selectedCountry, selectedStateCode);
    setCities([]);; // Clear existing cities
  };


  const loadCountries = () => {
    axios.get('https://api.countrystatecity.in/v1/countries', {
      headers: { 'X-CSCAPI-KEY': 'NHhvOEcyWk50N2Vna3VFTE00bFp3MjFKR0ZEOUhkZlg4RTk1MlJlaA==' }
    })
    .then(response => {
      setCountries(response.data);
    })
    .catch(error => console.error('Error loading countries:', error));
  };

  const loadStates = (selectedCountryCode) => {
    axios.get(`https://api.countrystatecity.in/v1/countries/${selectedCountryCode}/states`, {
      headers: { 'X-CSCAPI-KEY': 'NHhvOEcyWk50N2Vna3VFTE00bFp3MjFKR0ZEOUhkZlg4RTk1MlJlaA==' }
    })
    .then(response => {
      setStates(response.data);
    })
    .catch(error => console.error('Error loading states:', error));
  };

    const loadCities = (selectedCountryCode, selectedStateCode) => {
    console.log("selected CountryCode",selectedCountryCode)
    console.log("selected stateCode",selectedStateCode)

    axios.get(`https://api.countrystatecity.in/v1/countries/${selectedCountryCode}/states/${selectedStateCode}/cities`, {
      headers: { 'X-CSCAPI-KEY': 'NHhvOEcyWk50N2Vna3VFTE00bFp3MjFKR0ZEOUhkZlg4RTk1MlJlaA==' }
    })
    .then(response => {
      setCities(response.data);
    })
    .catch(error => console.error('Error loading cities:', error));
  };


  const getFormDetails = async (e)=>{
   
    try{

      const data = {
        CustomerCode:currentUser.user.merchantCode,
        token:currentUser.user.token,
      }
      console.log(data)
      const url = 'https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/merchant-kyc-details';
      let res = await axios.post(url,{...data});
      console.log("form data",res.data)

      const transformData = {
        business_name:res.data?.data?.business_name,
        business_model:res.data?.data?.business_model,
        doInc:res.data?.data?.doInc,
        tin:res.data?.data?.tin,
        zipCode:res.data?.data?.zipCode,
        city:res.data?.data?.city,
        company_type:res.data?.data?.company_type,
        incNumber:res.data?.data?.incNumber,
        coInc:res.data?.data?.coInc,
        address:res.data?.data?.address,
        company_state:res.data?.data?.company_state,
        biz_website:res.data?.data?.biz_website, 
      }

      const socialData = {
        website:res.data?.data?.website,
        twitter:res.data?.data?.twitter,
        linkedIn:res.data?.data?.linkedIn,
        instagram:res.data?.data?.instagram
      }
      const {pay_ins,pay_outs,pep,shareholders,in_store_payments,directors,conversions,abaas,additional_documents} = res.data?.data



      setInCertificate(res.data?.data?.incCert)
      setArticleAssoc(res.data?.data?.article_of_assoc)
      setUtilityBill(res.data?.data?.opBusUtilBill)
      setBusinessInfo(transformData)
      setPayIn(pay_ins)
      setPayOut(pay_outs)
      setCo(res.data?.data?.co2)
      setCoSeven(res.data?.data?.co7)
      // setPep(pep || [ {fullName:"",position:"",resAddress:""}])
      setAgency(abaas)
      setPayment(in_store_payments)
      setConversion(conversions)
      setSocialBuss(socialData)
      setDirectorInputs(directors || [ { firstName: '', lastName: '',position:"",idDoc:"",idNumber:"",issuedCountry:"",resAddress:"", validId: '', proofOfAddress: '' }])
      // setDirectorImg(directors[0]?.validId || "")
      const naturalP = shareholders[0]?.naturalPerson ? shareholders[0] : shareholders[1]
      const legal = shareholders[0]?.legalEntity ? shareholders[0] : shareholders[1]
      setShareholderInput([naturalP] || [ { firstName: '', lastName: '',percOfShares:"",nationality:"",naturalPerson:true,legalEntity:false}])
      //setShareholderImg(shareholders[0]?.validId || "")
      setLegalEntity([legal] || [  {entity_name: '', country_of_Inc: '',Inc_Number:"",percOfShares:"",naturalPerson:false,legalEntity:true}])
      setAdditionDoc(additional_documents || [{doc:""}])
    }catch(err){
      console.log(err)
      const errorMessage = err?.response?.data?.msg || "unable to save"
      // toast.error(errorMessage,{
      //   position: "top-left", pauseOnHover: true
      // })
    }
   
  }
    // shareholder Natural
   const saveNaturalShare = async (e)=>{
    e.preventDefault();
    setIsharehold(true)
    const CustomerCode = currentUser?.user?.merchantCode
    const token = currentUser?.user?.token

    try{

      // const formData = new FormData();

      const shareInput = shareholderInput.map((form) => ({
        naturalPerson:form.naturalPerson,
        legalEntity:form.legalEntity,
        firstName: form.firstName,
        lastName: form.lastName,
        percOfShares: form.percOfShares,
        dob: form.dob,
        nationality: form.nationality,
        idDoc: form.idDoc,
        idNumber: form.idNumber,
        issuedCountry: form.issuedCountry,
        resAddress: form.resAddress,
        validId:form.validId,
        
      }));

  
      const result = {
        CustomerCode,
        token,
        shareholders:[...shareInput]
      }

     
      const url = 'https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/merchant-kyc';
      let res = await axios.post(url,result);
      setIsharehold(false);     
      toast.success(res.data.msg,{ position: "top-left", pauseOnHover: true});
    }catch(err){
      setIsharehold(false)
      console.log(err)
      const errorMessage = err?.response?.data?.msg
      toast.error(errorMessage,{
        position: "top-left", pauseOnHover: true
      })

    }
   
  }


  const handleEntityChange = (event,index)=>{
    let data = [...legalEntity];
    data[index][event.target.name] = event.target.value;
    setLegalEntity(data);
  }

  const addEntity = ()=>{
    let object = {entity_name: '', country_of_Inc: '',Inc_Number:"",percOfShares:"",naturalPerson:false,legalEntity:true}
    setLegalEntity([...legalEntity,object])
  }


  const removeEntity = (index)=>{
    let data = [...legalEntity];
    data.splice(index,1)
    setLegalEntity(data);
  }

  const saveEntity = async (e)=>{
    e.preventDefault();
    setIsharehold(true)
    try{

      const data = {
        shareholders:[...legalEntity],
        CustomerCode:currentUser.user.merchantCode,
        token:currentUser.user.token,
      }
      console.log(data)
      const url = 'https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/merchant-kyc';
      let res = await axios.post(url,{...data});
      setIsharehold(false);
      toast.success(res.data.msg,{ position: "top-left", pauseOnHover: true});
    }catch(err){
      setIsharehold(false)
      console.log(err)
      const errorMessage = err?.response?.data?.msg || "unable to save"
      toast.error(errorMessage,{
        position: "top-left", pauseOnHover: true
      })
    }
   
  }

  
  // Director
  const handleDirChange = (event,index)=>{
    const { name, value } = event.target;
    let data = [...directorInputs];
    data[index][name] = value;
    setDirectorInputs(data);
  }

  
  const addDirector = ()=>{
    let object = {
      firstName: '', 
      lastName: '', 
      position: '', 
      dob: '', 
      idDoc: '', 
      bvn: '', 
      idNumber: '', 
      issuedCountry: '', 
      resAddress: '', 
      validId: '', 
      proofOfAddress: ''
    };
    setDirectorInputs([...directorInputs, object]);
    // let object ={fullName:"",position:"",resAddress:""}
    
    // setDirectorInputs([...directorInputs,object])
    // console.log("directorsinput",directorInputs)
  }

  const removeDirector = (index)=>{
    let data = [...directorInputs];
    data.splice(index,1)
    setDirectorInputs(data);
  }

  
  const saveDirector = async (e)=>{
    e.preventDefault();
    setIsDir(true)
    const CustomerCode = currentUser?.user?.merchantCode
    const token = currentUser?.user?.token
    try{

      const formData = new FormData();

      formData.append("token", token);
      formData.append("CustomerCode", CustomerCode);
      
      const directors = directorInputs.map((form) => ({
        firstName: form.firstName,
        lastName: form.lastName,
        position: form.position,
        dob: form.dob,
        bvn: form.bvn,
        idDoc: form.idDoc,
        idNumber: form.idNumber,
        issuedCountry: form.issuedCountry,
        resAddress: form.resAddress,
        validId:form.validId,
        proofOfAddress:form.proofOfAddress,
        
      }));

     
      const result = {
        CustomerCode,
        token,
        directors:[...directors]
      }
     
      const url = 'https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/merchant-kyc';
      let res = await axios.post(url,result);
      setIsDir(false);
      toast.success(res.data.msg,{
        position: "top-left", pauseOnHover: true
      });
    }catch(err){
      setIsDir(false)
      console.log(err)
      const errorMessage = err?.response?.data?.msg
      toast.error(errorMessage,{
        position: "top-left", pauseOnHover: true
      })

    }
   
  }

  // Pep
  const handlePepChange = (event,index)=>{
    let data = [...pep];
    data[index][event.target.name] = event.target.value;
    setPep(data);
  }

  const addPep = ()=>{
    let object ={fullName:"",position:"",resAddress:""}
    setPep([...pep,object])
  }

  // remove pep
  const removePep = (index)=>{
    let data = [...pep];
    data.splice(index,1)
    setPep(data);
  }

  // savePep
  const savePep = async (e)=>{
    e.preventDefault();
    setIsLoading(true)
    try{

      const data = {
        pep:[...pep],
        CustomerCode:currentUser.user.merchantCode,
        token:currentUser.user.token,
      }

      const url = 'https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/merchant-kyc';
      let res = await axios.post(url,{...data});
      setIsLoading(false);
      toast.success(res.data.msg,{ position: "top-left", pauseOnHover: true});
    }catch(err){
      console.log(err)
    }
   
  }
  const SaveBusinessInfo = async () =>{
    try{
      // const headers = {  
      //   "api_key": currentUser.user.merchantKey, 
      //   "sweetapi": currentUser.user.merchantCode  
      // }
       setIsLoading(true)
      // console.log("businessInfo",businessInfo)
      const data = {
        ...businessInfo,
        CustomerCode:currentUser.user.merchantCode,
        token:currentUser.user.token,
      }

      console.log(data)
      const url = 'https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/merchant-kyc';
      let res = await axios.post(url,{...data});
      
      setIsLoading(false);
      toast.success(res.data.msg,{
        position: "top-left", pauseOnHover: true
      });
     
    }catch(err){
      setIsLoading(false);
      console.log(err)
      const errorMessage = err?.response?.data?.message || "Server error"
      toast.error(errorMessage,{
        position: "top-left", pauseOnHover: true
      });
    }
   
    
  }

  const saveUtilities = async (e)=>{
    e.preventDefault();
    setIsUtility(true);

    try{
      
      const data = {
        CustomerCode:currentUser?.user?.merchantCode,
        token:currentUser?.user?.token,
        pay_ins:{
           ...payIn
        },
        pay_outs:{
         ...payOut
        },
        abaas:{
         ...agency
        },
         in_store_payments:{
        ...payment
        },
        conversions:{
        ...conversion
        }
      }
      
      const url = 'https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/merchant-kyc';
      let res = await axios.post(url,{...data});
      setIsUtility(false);
      toast.success(res.data.msg,{
        position: "top-left", pauseOnHover: true
      });

    }catch(err){
      setIsUtility(false);
      console.log(err)
      const errorMessage = err?.response?.data?.msg
      toast.error(errorMessage,{
        position: "top-left", pauseOnHover: true
      })
    }
  }

  const saveSocial = async (e)=>{
    e.preventDefault();
    setIsSocial(true);

    try{
      
      const data = {
        CustomerCode:currentUser?.user?.merchantCode,
        token:currentUser?.user?.token,
        ...socialBuss
        
      }

     
      const url = 'https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/merchant-kyc';
      let res = await axios.post(url,{...data});
      setIsSocial(false);
      toast.success(res?.data?.msg,{
        position: "top-left", pauseOnHover: true
      });

    }catch(err){
      setIsSocial(false);
      console.log(err)
      const errorMessage = err?.response?.data?.msg
      toast.error(errorMessage,{
        position: "top-left", pauseOnHover: true
      })
    }
  }

  const handleSocialBuss = ()=>{
    
  }

  // shareholder switch

  const handleShareHolder = (event) => {
    const newValue = event.target.value;

    setShareholder((prevValue) => {
      if (prevValue === newValue) {
        return ''; 
      } else {
        return newValue; 
      }
    });

  };


  // Director
  useEffect(() => {
    if (!DirectorID) {
        setDPreview(undefined)
        return
    }
    const objectUrl = URL.createObjectURL(DirectorID)
    setDPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [DirectorID])




  // shareHolder preview
  
  useEffect(() => {
    if (!shareholderID) {
        setShareholderPreview(undefined)
        return
    }
    const objectUrl = URL.createObjectURL(shareholderID)
    setShareholderPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [shareholderID])

  // bussiness  info
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBusinessInfo((prevProfileInfo) => ({
      ...prevProfileInfo,
      [name]: value,
    }));
  };
  const handleInputSocial = (e) => {
    const { name, value } = e.target;
    setSocialBuss((prevProfileInfo) => ({
      ...prevProfileInfo,
      [name]: value,
    }));
  };

  // incorporation Certificate
  const handleInCertificateDragOver = (event)=>{
    event.preventDefault();
  }


  const handleInCertificateDrop = async (event) => {
    const {files} = event.dataTransfer;
    event.preventDefault();
    if (!event.dataTransfer.files || event.dataTransfer.files.length === 0) {
      setInCertificate(undefined)
      return
    }
    const file = files[0];
    const form = new FormData();
    form.append("file",file);
    try{
      const url = 'https://flick-payments-3623f34e4cf7.herokuapp.com/file-upload-test';
      let res = await axios.post(url,form);
      setInCertificate(res?.data?.url)
    }catch(err){
      console.log(err)
    }
    
  };

  const handleInCertificate = async (e)=>{
    const { name, files } = e.target;
    if (!e.target.files || e.target.files.length === 0) {
      setInCertificate(undefined)
      return
    }
    const file = files[0];
    setInCertificateFile(file)
    const form = new FormData();
    form.append("file",file);
    try{
      const url = 'https://flick-payments-3623f34e4cf7.herokuapp.com/file-upload-test';
      let res = await axios.post(url,form,{
        onUploadProgress: progressEvent => {
           setUploadPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );
        }
      });
      // clear percentage
      setTimeout(() => setUploadPercentage(0), 10000);
      setInCertificate(res?.data?.url)
    }catch(err){
      console.log(err)
    }
    
  }


  // Article of Association
  const handleArtAssocDragOver = (event)=>{
    event.preventDefault();
  }


  const handleIArtAssocDrop = async (e) => {
    const { files } = e.dataTransfer;
    e.preventDefault();
    if (!e.dataTransfer.files || e.dataTransfer.files.length === 0) {
      setArticleAssoc(undefined)
      return
    }
    const file = files[0];
    const form = new FormData();
    form.append("file",file);
    // setArticleAssoc(event.dataTransfer.files[0])
    try{
      const url = 'https://flick-payments-3623f34e4cf7.herokuapp.com/file-upload-test';
      let res = await axios.post(url,form,{
        onUploadProgress: progressEvent => {
           setAsUploadPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );
        }
      });
      // clear percentage
      setTimeout(() => setAsUploadPercentage(0), 10000);
      setArticleAssoc(res?.data?.url)
    }catch(err){
      console.log(err)
    }
  };

  const handleArtAssoc = async (e)=>{
    const { name, files } = e.target;
    if (!e.target.files || e.target.files.length === 0) {
      setArticleAssoc(undefined)
      return
    }
    const file = files[0];
    setArticleAssocFile(file);
    const form = new FormData();
    form.append("file",file);
    try{
      const url = 'https://flick-payments-3623f34e4cf7.herokuapp.com/file-upload-test';
      let res = await axios.post(url,form,{
        onUploadProgress: progressEvent => {
           setAsUploadPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );
        }
      });
      setTimeout(() => setAsUploadPercentage(0), 10000);
      setArticleAssoc(res?.data?.url)
    }catch(err){
      console.log(err)
    }
   
  }

   // Article of Association
  const handleUtilityDragOver = (event)=>{
    event.preventDefault();
  }


  const handleUtilityDrop = async (event) => {
    const {files} = event.dataTransfer;
    event.preventDefault();
    if (!event.dataTransfer.files || event.dataTransfer.files.length === 0) {
      setUtilityBill(undefined)
      return
    }
    const file = files[0];
    const form = new FormData();
    form.append("file",file);
    try{
      const url = 'https://flick-payments-3623f34e4cf7.herokuapp.com/file-upload-test';
      let res = await axios.post(url,form,{
        onUploadProgress: progressEvent => {
          setOpUploadPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );
        }
      });
      setTimeout(() => setOpUploadPercentage(0), 10000);
      setUtilityBill(res?.data?.url)
    }catch(err){
      console.log(err)
    }
  
  };

  const handleUtilityBill = async (e)=>{
    const { name, files } = e.target;
    if (!e.target.files || e.target.files.length === 0) {
      setUtilityBill(undefined)
      return
    }
    const file = files[0];
    setUtilityBillFile(file);
    const form = new FormData();
    form.append("file",file);
    try{
      const url = 'https://flick-payments-3623f34e4cf7.herokuapp.com/file-upload-test';
      let res = await axios.post(url,form,{
        onUploadProgress: progressEvent => {
          setOpUploadPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );
        }
      });
      setTimeout(() => setOpUploadPercentage(0), 10000);
      setUtilityBill(res?.data?.url)
    }catch(err){
      console.log(err)
    }
  
  }



  // ShareHolder file uplaod
  const handleShareHolderDragOver = (event) => {
    event.preventDefault();
  };

  const handleShareHolderDrop = (event) => {
    event.preventDefault();
    if (!event.dataTransfer.files || event.dataTransfer.files.length === 0) {
      setShareholderID(undefined)
      return
    }
    setShareholderID(event.dataTransfer.files[0])
  };


  const handleShareHolderFileChange = async (e,index)=>{
    
    setIsShareChange(true)
    const CustomerCode = currentUser?.user?.merchantCode
    const token = currentUser?.user?.token

    const { name, files } = e.target;
    if (!e.target.files || e.target.files.length === 0) {
      setShareholderID(undefined)
      return
    }
    const file = files[0];
    console.log("shareHolder",file)
    const form = new FormData();
    form.append("file",file);
    // form.append("CustomerCode",CustomerCode)
    // form.append("token",token)
    try{
      const url = 'https://flick-payments-3623f34e4cf7.herokuapp.com/file-upload-test';
      let res = await axios.post(url,form,{
        onUploadProgress: progressEvent => {
          setSharePercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );
        }
      });

      
     
      let data = [...shareholderInput];
      data[index][name] = res?.data?.url;
      setShareholderInput(data);
      if (name === 'validId') {
        
        setShareholderID(e.target.files[0])
      }

      setShareholderInput(data);
      console.log("input",shareholderInput)

      setTimeout(() =>{
        setSharePercentage(0);
        setIsShareChange(false)
      } , 10000);
    }catch(err){
      setIsShareChange(false)
      console.log(err)
    }
  }

  const handleAddressFileChange = async (e,index) => {
    const CustomerCode = currentUser?.user?.merchantCode
    const token = currentUser?.user?.token
    const { name, files } = e.target;
    const file = files[0];
    setAddressFile(file)
    const form = new FormData();
    form.append("file",file);
    // form.append("CustomerCode",CustomerCode)
    // form.append("token",token)
    try{
      const url = 'https://flick-payments-3623f34e4cf7.herokuapp.com/file-upload-test';
      let res = await axios.post(url,form);
      
      let data = [...directorInputs];
      data[index][name] = res?.data?.url;
      setDirectorInputs(data);
      
    }catch(err){
      console.log(err)
    }
  }

  // Director upload
  const handleDragOverD = (event) => {
    event.preventDefault();
  };

  const handleDropD = (event) => {
    event.preventDefault();
    if (!event.dataTransfer.files || event.dataTransfer.files.length === 0) {
      setDirectorID(undefined)
      return
    }
    setDirectorID(event.dataTransfer.files[0])
  };





  // ===================== to be removed ==================

  const handleDirFileChange = async (e, index) => {
   
    const { name, files } = e.target;
    if (!files || files.length === 0) return;

    setDirPercentage(0);
    setIsDirChange(true);

    const file = files[0];
    const form = new FormData();
    form.append("file", file);

    try {
      const url = 'https://flick-payments-3623f34e4cf7.herokuapp.com/file-upload-test';
      let res = await axios.post(url,form,{
        onUploadProgress: progressEvent => {
          setDirPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );
        }
      });

      let data = [...directorInputs];
      data[index][name] = res?.data?.url;
      // setDirectorID(e.target.files[0])
      // setDirectorAddressID(e.target.files[0])
      setDirectorInputs(data);
      setTimeout(() =>{
        setDirPercentage(0);
        setIsDirChange(false)
      } , 2000);

    } catch (err) {
      console.log(err);
      // Error handling
    }
  };

  // ============

  // const handleDirFileChange = async (e,index)=>{
  //   setDirPercentage(0);
  //   setIsDirChange(true);
  //   const CustomerCode = currentUser?.user?.merchantCode
  //   const token = currentUser?.user?.token
  //   const { name, files } = e.target;
  //   if (!e.target.files || e.target.files.length === 0) {
  //     setDirectorID(undefined)
  //     return
  //   }
    
  //   const file = files[0];
  //   const form = new FormData();
  //   form.append("file",file);

  //   // form.append("CustomerCode",CustomerCode)
  //   // form.append("token",token)

  //   try{
  //     const url = 'https://flick-payments-3623f34e4cf7.herokuapp.com/file-upload-test';
  //     // let res = await axios.post(url,form);
  //     let res = await axios.post(url,form,{
  //       onUploadProgress: progressEvent => {
  //         setDirPercentage(
  //           parseInt(
  //             Math.round((progressEvent.loaded * 100) / progressEvent.total)
  //           )
  //         );
  //       }
  //     });

      
  //     let data = [...directorInputs];
     
  //     if (name === 'validId') {
  //       data[index][name] = res?.data?.url;
  //       setDirectorID(e.target.files[0])
  //     }
  //     if (name === 'proofOfAddress') {
  //       data[index][name] = res?.data?.url;
  //       setDirectorAddressID(e.target.files[0])
  //     }
  //     setDirectorInputs(data);
  //     setTimeout(() =>{
  //       setDirPercentage(0);
  //       setIsDirChange(false)
  //     } , 3000);
      
  //   }catch(err){
  //     setIsDirChange(false)
  //     console.log(err)
  //     const errorMessage = err?.response?.data.message || "fail to upload"
  //     toast.error(errorMessage)
  //   }
    
   
  // }


    // CO7
    const handleCOSevenDragOver = (e)=>{
      e.preventDefault();
    }
  
    const handleCOSevenDrop = async (event)=>{
      const {files} = event.dataTransfer;
      event.preventDefault();
      if (!event.dataTransfer.files || event.dataTransfer.files.length === 0) {
        setCoSeven(undefined)
        return
      }
      const file = files[0];
      const form = new FormData();
      form.append("file",file);
      try{
        const url = 'https://flick-payments-3623f34e4cf7.herokuapp.com/file-upload-test';
        let res = await axios.post(url,form,{
          onUploadProgress: progressEvent => {
            setCoSevenPercentage(
              parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            );
          }
        });
        setTimeout(() => setCoSevenPercentage(0), 10000);
        setCoSeven(res?.data?.url)
      }catch(err){
        console.log(err)
      }
    }
  
    const handleCOSeven = async (e)=>{
      const { name, files } = e.target;
      if (!e.target.files || e.target.files.length === 0) {
        setCoSeven(undefined)
        return
      }

      const file = files[0];
      setCoSevenFile(file);
      const form = new FormData();
      form.append("file",file);
      try{
        const url = 'https://flick-payments-3623f34e4cf7.herokuapp.com/file-upload-test';
        let res = await axios.post(url,form,{
          onUploadProgress: progressEvent => {
            setCoSevenPercentage(
              parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            );
          }
        });
        setTimeout(() => setCoSevenPercentage(0), 10000);
        setCoSeven(res?.data?.url)
      }catch(err){
        console.log(err)
      }
    
  }

  // CO2
  const handleCODragOver = (e)=>{
    e.preventDefault();
  }

  const handleCODrop = async (event)=>{
    const {files} = event.dataTransfer;
    event.preventDefault();
    if (!event.dataTransfer.files || event.dataTransfer.files.length === 0) {
      setCo(undefined)
      return
    }
    const file = files[0];
    const form = new FormData();
    form.append("file",file);
    try{
      const url = 'https://flick-payments-3623f34e4cf7.herokuapp.com/file-upload-test';
      let res = await axios.post(url,form,{
        onUploadProgress: progressEvent => {
          setCoPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );
        }
      });
      setTimeout(() => setCoPercentage(0), 10000);
      setCo(res?.data?.url)
    }catch(err){
      console.log(err)
    }
  }

  const handleCO = async (e)=>{
    const { name, files } = e.target;
    if (!e.target.files || e.target.files.length === 0) {
      setCo(undefined)
      return
    }

    const file = files[0];
    setCoFile(file);
    const form = new FormData();
    form.append("file",file);
    try{
      const url = 'https://flick-payments-3623f34e4cf7.herokuapp.com/file-upload-test';
      let res = await axios.post(url,form,{
        onUploadProgress: progressEvent => {
          setCoPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );
        }
      });
      setTimeout(() => setCoPercentage(0), 10000);
      setCo(res?.data?.url)
    }catch(err){
      console.log(err)
    }
  
  }



    // Additional documents
    const handleAdditionDragOver = (e,index)=>{
      e.preventDefault();
    }

    // const addDirector = ()=>{
    //   let object ={fullName:"",position:"",resAddress:""}
      
    //   setDirectorInputs([...directorInputs,object])
    //   console.log("directorsinput",directorInputs)
    // }

    const addOptionalDoc = () =>{
      let object = {doc:""}
      setAdditionDoc([...additionDoc,object])
      
    }

    const removeDoc = (index)=>{
      let data = [...additionDoc];
      data.splice(index,1)
      setAdditionDoc(data);
    }
  
    const handleAdditionDrop = async (e,index)=>{
      const {files} = e.dataTransfer;
      e.preventDefault();
      
      if (!e.dataTransfer.files || e.dataTransfer.files.length === 0) {
        setAddition(undefined)
        return
      }
      const file = files[0];
      const form = new FormData();
      form.append("file",file);
      try{
        const url = 'https://flick-payments-3623f34e4cf7.herokuapp.com/file-upload-test';
        let res = await axios.post(url,form,{
          onUploadProgress: progressEvent => {
            setAdditionPercentage(
              parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            );
          }
        });
        let data = [...additionDoc]
        data[index]["doc"] = res?.data?.url
        setAdditionDoc(data)
        setTimeout(() => setAdditionPercentage(0), 1000);
      }catch(err){
        console.log(err)
      }
    }
  
    const handleAdditional = async (e,index)=>{

      const { name,files } = e.target;

      try{
        
        if (!e.target.files || e.target.files.length === 0) {
          setAddition(undefined)
          return
        }

        const file = files[0];
        setAdditionFile(file);
        const form = new FormData();
        form.append("file",file);
      
        const url = 'https://flick-payments-3623f34e4cf7.herokuapp.com/file-upload-test';
        let res = await axios.post(url,form,{
          onUploadProgress: progressEvent => {
            setAdditionPercentage(
              parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            );
          }
        });
        let data = [...additionDoc]
        data[index][name] = res?.data?.url
        setAdditionDoc(data)
        setTimeout(() => setAdditionPercentage(0), 1000);
        
      }catch(err){
        console.log(err)
      }
    
  }






  const handlePayIn = (event) => {
    const checkboxName = event.target.name;
    const newValue = event.target.checked;

    setPayIn((prevValues) => ({
      ...prevValues,
      [checkboxName]: newValue,
    }));
  }

  const handlePayOut = (event) => {
    const checkboxName = event.target.name;
    const newValue = event.target.checked;

    setPayOut((prevValues) => ({
      ...prevValues,
      [checkboxName]: newValue,
    }));
  }

  const handleAgency = (event) => {
    const checkboxName = event.target.name;
    const newValue = event.target.checked;

    setAgency((prevValues) => ({
      ...prevValues,
      [checkboxName]: newValue,
    }));
  }

  // payment checkbos
  const handlePayment = (event) => {
    const checkboxName = event.target.name;
    const newValue = event.target.checked;

    setPayment((prevValues) => ({
      ...prevValues,
      [checkboxName]: newValue,
    }));
  }
  // payment checkbos
  const handleConversion= (event) => {
    const checkboxName = event.target.name;
    const newValue = event.target.checked;

    setConversion((prevValues) => ({
      ...prevValues,
      [checkboxName]: newValue,
    }));
  }


  const handleForm = (e)=>{
    e.preventDefault()
  }

  const saveDocuments = async (e)=>{
    e.preventDefault();
   
    // const formData = new FormData();
    
    setIsDoc(true);

    
   
    
    try{
        
        const data = {
          incCert:IncCertificate,
          article_of_assoc:articleOfAssoc,
          opBusUtilBill:opBusUtilBill,
          co2:co,
          co7:coSeven,
          additional_documents:additionDoc,
          CustomerCode:currentUser?.user?.merchantCode,
          token:currentUser?.user?.token,
        }
        
        
        const url = 'https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/merchant-kyc';
        // const url = 'http://localhost:5500/upload';
        let res = await axios.post(url,data);
        setIsDoc(false);
        toast.success(res?.data?.msg,{
          position: "top-left", pauseOnHover: true
      });
      
      

    }catch(err){
      setIsDoc(false)
      console.log(err)
      const errorMessage = err?.response?.data?.msg || "Unable to save"
      toast.error(errorMessage,{
        position: "top-left", pauseOnHover: true
    })
    }

   
  }


  const handleLogout = () =>{
    dispatch(logout())
    navigate('/kyc-success')
  }
  const handleSubmitKyc = async (e)=>{
    setIsFormSubLoading(true)
    e.preventDefault();
    let shareholderValues =[];

    if(shareholderInput[0].naturalPerson){
      shareholderValues = [
        ...shareholderInput
      ]
    }else{
      shareholderValues = [
        ...legalEntity
      ]
    }
    try{

      const data = {
        CustomerCode:currentUser.user.merchantCode,
        token:currentUser.user.token,
        // 
        business_name: businessInfo.business_name,
        business_model:businessInfo.business_model,
        doInc:businessInfo.doInc,
        tin:businessInfo.tin,
        zipCode:businessInfo.zipCode,
        city:businessInfo.city,
        company_type:businessInfo.company_type,
        incNumber:businessInfo.incNumber,
        coInc:businessInfo.coInc,
        address:businessInfo.address,
        company_state:businessInfo.company_state,
        biz_website:businessInfo.biz_website,



        // additional info

        finalSubmission:true,

        // certiciates

        incCert :IncCertificate,
        article_of_assoc:articleOfAssoc,
        opBusUtilBill:opBusUtilBill,
        co2:co,
        co7:coSeven,
        additional_documents:additionDoc,

        pay_ins:{
          ...payIn
        },
        pay_outs:{
          ...payOut
        },
        abaas:{
          ...agency
        },
        in_store_payments:{
          ...payment
        },
        conversions:{
          ...conversion
        },
        directors:[
          ...directorInputs
        ],
        shareholders:[  
            ...shareholderValues
        ]
        
      } 

      const url = 'https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/merchant-kyc';
      let res = await axios.post(url,{...data});
      setIsFormSubLoading(false)
      toast.success("form submitted successfully",{
        position: "top-left", pauseOnHover: true
      });
      const kycComplete = res?.data
    
      // dispatch(logout())
      navigate('/kyc-success')
      // handleLogout();
      // setTimeout(() => navigate('/getstarted',{state:{kycComplete}}), 4000);
      // To track the KYC completion with Brevo
      window.sendinblue.track('KYC Completed', {
        email: currentUser.user.business_email,
        userId: currentUser.user.id,
      });
     
    }catch(err){
      setIsFormSubLoading(false)
      console.log(err)
      const errorMessage = err?.response?.data?.msg || "unable to save"
      toast.error(errorMessage,{
        position: "top-left", pauseOnHover: true
      })
    }
  }
  return (
 
    <div className='back-introduce'>
        <div className="go-back-btn" onClick={handleBack}>
            <img
              src={process.env.PUBLIC_URL + "/images/arrow-narrow-right.svg"}
              alt="arrow"
            />
            <h1 className="btn-title"> Go Back</h1>
        </div>

        <div className="introduces">
      <BusinessOverview />
      <div className="overview-session">
      <session className="main-form" id="introduction">
        <div className="main-form-wrapper">

        <div className="info-form-title">
          <p className="provide-info">Provide information about your business.</p>
          <p className="share-details">Share some details about yourself and your business.</p>
        </div>

        {/* form data */}
        <form onSubmit={handleForm} className="info-form">
            <div className="info-form-left">
            <div className='form-groups'>
                <label className='lbl'>Business Name*</label>
                <input value={businessInfo.business_name} onChange={handleInputChange}  type='text' name="business_name" placeholder='Flick' className='form-control'/>
            </div>
            <div className='form-groups'>
                <label className='lbl'>Business Model*</label>
                {/* <input value={businessInfo.business_model} onChange={handleInputChange} type='text' name="business_model" placeholder='Enter your name' className='form-control' /> */}
                <select
                  className='form-control'
                  aria-label="Default select example"
                  onChange={handleInputChange}
                  name='business_model'
                  value={businessInfo.business_model}
                >
                  <option value="">Select Business Model</option>
                  <option  value="b2b">B2B</option>
                  <option  value="b2c">B2C</option>
                </select>
            </div>
            <div className='form-groups'>
                <label className='lbl'>Date of Incorporation*</label>
                <input value={businessInfo.doInc} onChange={handleInputChange} type='date' name="doInc" placeholder='Enter your name' className='form-control' />
            </div>
            <div className='form-groups'>
                <label className='lbl'>Tax Number*</label>
                <input value={businessInfo.tin} onChange={handleInputChange} type='text' name="tin" placeholder='HJN637DHBCHE73' className='form-control' />
            </div>
            <div className='form-groups'>
                <label className='lbl'>Zip Code*</label>
                <input value={businessInfo.zipCode} onChange={handleInputChange} type='text' name="zipCode" placeholder='Zip Code' className='form-control' />
            </div>
            <div className='form-groups'>
                <label className='lbl'>City*</label>
                <select onChange={handleInputChange} value={businessInfo.city} className='form-control' aria-label="Default select example" name="city">
                  <option  value="">Select City</option>
                  {cities.map(city => (
                    <option key={city.iso2} value={city.iso2}>{city.name}</option>
                  ))}
                </select>
                {/* <input value={businessInfo.city} onChange={handleInputChange} type='text' name="city" placeholder='Enter your name' className='form-control' /> */}
            </div>
            </div>

            <div className="info-form-right">
            <div className='form-groups'>
                <label className='lbl'>Company Type*</label>
                <input value={businessInfo.company_type} onChange={handleInputChange} type='text' name="company_type" placeholder='Enter your name' className='form-control' />
            </div>
            <div className='form-groups'>
                <label className='lbl'>Incorporation Number*</label>
                <input value={businessInfo.incNumber} onChange={handleInputChange} type='text' name="incNumber" placeholder='GHJDC67Y4HFBNDJ4RF4' className='form-control' />
            </div>

            <div className='form-groups'>
                <label className='lbl'>Country of Incorporation*</label>
                <select
                    className='form-control'
                    aria-label="Default select example"
                    onChange={handleCountryChange}
                    name="coInc"
                    
                  >
                    <option value="">Select Country</option>
                    {countries.map(country => (
                      <option key={country.iso2} value={country.iso2}>{country.name}</option>
                    ))}
                  </select>
                {/* <input value={businessInfo.coInc} onChange={handleInputChange} type='text' name="coInc" placeholder='Enter your name' className='form-control' /> */}
            </div>
            <div className='form-groups'>
                <label className='lbl'>Company Address*</label>
                <input value={businessInfo.address} onChange={handleInputChange} type='text' name="address" placeholder='Enter Address' className='form-control' />
            </div>
            <div className='form-groups'>
                <label className='lbl'>State*</label>
                <select
                    className='form-control'
                    aria-label="Default select example"
                    onChange={handleStateChange}
                    name="company_state"
                    
                  >
                    <option value="">Select State</option>
                    {states.map(state => (
                      <option key={state.iso2} value={state.iso2}>{state.name}</option>
                    ))}
                
                </select>
                {/* <input value={businessInfo.company_state} onChange={handleInputChange} type='text' name="company_state" placeholder='Enter your name' className='form-control' /> */}
            </div>
            <div className='form-groups'>
                <label className='lbl'>Company Website*</label>
                <input value={businessInfo.biz_website} onChange={handleInputChange} type='text' name="biz_website" placeholder='company website' className='form-control' />
            </div>
            </div>
        </form>
            <button className='btn' style={{width:"fit-content"}} onClick={SaveBusinessInfo}>
                {
                isLoading ? (
                  <BeatLoader
                  color="#FFF"
                  cssOverride={{}}
                />
                  
                ) : (
                  <>
                    Save Changes
                  </>
                )
              }
            </button>
        {/*  */}
        </div>
      </session>


    

      <session className="main-form" id="utilise">
         <div className='utilise_flick_title'>
            <h1 className='utilize_h'>How do you plan to utilise Flick?</h1>
            <p className='utiliz'>This information will assist us in customizing a better experience for your business.</p>
         </div>
         
         <div className='payout_container'>

            {/* Pay-In */}
            <div className='pays_in'>
                <h1 className='pay_ins_title'>Collections</h1>
                <p className="pay_in_desc">Receive direct payments from businesses and individuals.</p>
                <div className='pay_line'></div>

                <div className='pay_in_checks'>
                    <div className="check_div">
                      <input 
                      type="checkbox" 
                      className="checkbox" 
                      name="virtual_accounts"
                      checked={payIn?.virtual_accounts} 
                      onChange={handlePayIn}
                      />
                      <h2 className="text_check">Virtual Nuban (transfer)</h2>
                    </div>

                    <div className="check_div">
                      <input 
                      type="checkbox" 
                      className="checkbox"
                      name="checkout_card"
                      checked={payIn?.checkout_card} 
                      onChange={handlePayIn} 
                      />
                      <h2 className="text_check">Checkout (Cards)</h2>
                    </div>

                    <div className="check_div">
                      <input 
                      type="checkbox" 
                      className="checkbox"
                      name="checkout_bank"
                      checked={payIn?.checkout_bank} 
                      onChange={handlePayIn} 
                      />
                      <h2 className="text_check">Pay by Bank</h2>
                    </div>
                    <div className="check_div">
                      <input 
                      type="checkbox" 
                      className="checkbox"
                      name="multi_currency"
                      checked={payIn?.multi_currency} 
                      onChange={handlePayIn} 
                      />
                      <h2 className="text_check">Multi-Currency</h2>
                    </div>
                </div>
            </div>

            {/* pay-outs */}
            <div className='pays_in'>
                <h1 className='pay_ins_title'>Pay-Outs</h1>
                <p className="pay_in_desc">Directly transfer funds to beneficiary accounts.</p>
                <div className='pay_line'></div>

                <div className='pay_in_checks'>
                    <div className="check_div">
                      <input 
                      type="checkbox" 
                      className="checkbox" 
                      name="local_pay_outs"
                      checked={payOut?.local_pay_outs} 
                      onChange={handlePayOut}
                      />
                      <h2 className="text_check">Local Pay-Outs</h2>
                    </div>

                    <div className="check_div">
                      <input 
                      type="checkbox" 
                      className="checkbox"
                      name="international_pay_outs"
                      checked={payOut?.international_pay_outs} 
                      onChange={handlePayOut} 
                      />
                      <h2 className="text_check">International Pay-outs</h2>
                    </div>

                    
                </div>
            </div>

            {/* Agency Banking */}

            <div className='pays_in'>
                <h1 className='pay_ins_title'>Financial Data</h1>
                <p className="pay_in_desc">Access high-quality financial data</p>
                <div className='pay_line'></div>

                <div className='pay_in_checks'>
                    <div className="check_div" style={{alignItems:"flex-start"}}>
                      <input 
                      type="checkbox" 
                      className="checkbox" 
                      name="transaction"
                      checked={agency?.transaction} 
                      onChange={handleAgency}
                      />
                      <h2 className="text_check">Transactions</h2>
                    </div>                    
                    <div className="check_div" style={{alignItems:"flex-start"}}>
                      <input 
                      type="checkbox" 
                      className="checkbox" 
                      name="statement"
                      checked={agency?.statement} 
                      onChange={handleAgency}
                      />
                      <h2 className="text_check">Statement</h2>
                    </div>                    
                    <div className="check_div" style={{alignItems:"flex-start"}}>
                      <input 
                      type="checkbox" 
                      className="checkbox" 
                      name="balance"
                      checked={agency?.balance} 
                      onChange={handleAgency}
                      />
                      <h2 className="text_check">Balance</h2>
                    </div>                    
                    <div className="check_div" style={{alignItems:"flex-start"}}>
                      <input 
                      type="checkbox" 
                      className="checkbox" 
                      name="credit_report"
                      checked={agency?.credit_report} 
                      onChange={handleAgency}
                      />
                      <h2 className="text_check">Credit Report</h2>
                    </div>                    
                </div>

            </div>

            {/* in-store payments */}
            

            <div className='pays_in'>
                <h1 className='pay_ins_title'>Identity/Onboarding</h1>
                <p className="pay_in_desc">Verify identiy easily and more efficiently</p>
                <div className='pay_line'></div>

                <div className='pay_in_checks'>
                    <div className="check_div" style={{alignItems:"flex-start"}}>
                      <input 
                      type="checkbox" 
                      className="checkbox" 
                      name="kyc"
                      checked={payment?.kyc} 
                      onChange={handlePayment}
                      />
                      <h2 className="text_check">Individual (KYC)</h2>
                    </div>
                    <div className="check_div" style={{alignItems:"flex-start"}}>
                      <input 
                      type="checkbox" 
                      className="checkbox" 
                      name="kyb"
                      checked={payment?.kyb} 
                      onChange={handlePayment}
                      />
                      <h2 className="text_check">Business (KYB)</h2>
                    </div>
                    <div className="check_div" style={{alignItems:"flex-start"}}>
                      <input 
                      type="checkbox" 
                      className="checkbox" 
                      name="widget"
                      checked={payment?.widget} 
                      onChange={handlePayment}
                      />
                      <h2 className="text_check">Widget</h2>
                    </div>
                    <div className="check_div" style={{alignItems:"flex-start"}}>
                      <input 
                      type="checkbox" 
                      className="checkbox" 
                      name="api"
                      checked={payment?.api} 
                      onChange={handlePayment}
                      />
                      <h2 className="text_check">API</h2>
                    </div>
                </div>
            </div>

            {/* Conversions */}

            <div className='pays_in'>
                <h1 className='pay_ins_title'>Country</h1>
                <p className="pay_in_desc">Select preferred Country</p>
                <div className='pay_line'></div>

                <div className='pay_in_checks'>
                    <div className="check_div" style={{alignItems:"flex-start"}}>
                      <input 
                      type="checkbox" 
                      className="checkbox" 
                      name="nigeria"
                      checked={conversion?.nigeria} 
                      onChange={handleConversion}
                      />
                      <h2 className="text_check">Nigeria</h2>
                    </div>
                    <div className="check_div" style={{alignItems:"flex-start"}}>
                      <input 
                      type="checkbox" 
                      className="checkbox" 
                      name="ghana"
                      checked={conversion?.ghana} 
                      onChange={handleConversion}
                      />
                      <h2 className="text_check">Ghana</h2>
                    </div>
                    <div className="check_div" style={{alignItems:"flex-start"}}>
                      <input 
                      type="checkbox" 
                      className="checkbox" 
                      name="kenya"
                      checked={conversion?.kenya} 
                      onChange={handleConversion}
                      />
                      <h2 className="text_check">Kenya</h2>
                    </div>
                    <div className="check_div" style={{alignItems:"flex-start"}}>
                      <input 
                      type="checkbox" 
                      className="checkbox" 
                      name="southAfrica"
                      checked={conversion?.southAfrica} 
                      onChange={handleConversion}
                      />
                      <h2 className="text_check">South Africa</h2>
                    </div>
                  
                </div>
            </div>

            {/*  */}
            <div className='pattern_form'>
              <h1 className='parttern_text'>
                Register your business <br/> in 1 week &nbsp;
                {/* eslint-disable-next-line */}
               <a className='biz_reg' href='https://docs.google.com/forms/d/e/1FAIpQLSfzfHveha51kLtPZY1vDFMOPaYg8aQM40VRgUo1jvMP4xXjSA/viewform?usp=pp_url' target='_blank'>Register</a> 
               </h1>
              <img className='img_partt' src='/images/bgLineParttern.svg' alt='parttern' />
            </div>
         </div>
         <button onClick={saveUtilities} className='btn' style={{width:"fit-content", marginTop:"2rem"}}>
         {
            isUtility ? (
              <BeatLoader
              color="#FFF"
              cssOverride={{}}
            />
              
            ) : (
              <>
                Save Changes
              </>
            )
          }
         </button>
      </session>

        {/* Director */}
          <session className="main-form" id="directors">
            
                <div className="main-form-wrapper">
                  <div className="info-form-title">
                    <p className="provide-info">Director</p>
                    <p className="share-details">
                      Please provide details about your directors.
                    </p>
                  </div>
                {
                  directorInputs?.map((form,index)=>{
                    
                    return (
                      <div key={index}>

                        <form className="info-form">
                          <div className="info-form-left">
                            <div className="form-groups">
                              <label className="lbl">First Name*</label>
                              <input
                                type="text"
                                name="firstName"
                                value={form.firstName}
                                placeholder="Mide"
                                onChange={event => handleDirChange(event,index)}
                                className="form-control"
                              />
                            </div>
                            <div className="form-groups">
                              <label className="lbl">Position*</label>
                              <input
                                type="text"
                                name="position"
                                value={form?.position}
                                onChange={event => handleDirChange(event,index)}
                                placeholder="Enter position"
                                className="form-control"
                              />
                            </div>
                            
                            <div className="form-groups">
                              <label className="lbl">Country/Nationality*</label>
                              
                              <select
                                className='form-control'
                                aria-label="Default select example"
                                onChange={event => handleDirChange(event,index)}
                                name='issuedCountry'
                                value={form.issuedCountry}
                              >
                                
                                <option value="">Select Country</option>
                                <option value="Nigeria">Nigeria</option>
                                <option value="Ghana">Ghana</option>
                                <option value="Kenya">Kenya</option>
                                <option value="UK">United Kingdom</option>
                                <option value="US">United State</option>
                              </select>
                            </div>
                            <div className="form-groups">
                              <label className="lbl">Identification Number*</label>
                              <input
                                type="text"
                                name="idNumber"
                                value={form.idNumber}
                                onChange={event => handleDirChange(event,index)}
                                placeholder="HJN637DHBCHE73"
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="info-form-right">
                            <div className="form-groups">
                              <label className="lbl">Last Name*</label>
                              <input
                                type="text"
                                name="lastName"
                                value={form.lastName}
                                placeholder="Enter Name"
                                className="form-control"
                                onChange={event => handleDirChange(event,index)}
                              />
                            </div>
                            <div className="form-groups">
                              <label className="lbl"> Date of Birth*</label>
                              <input
                                type="date"
                                name='dob'
                                value={form.dob}
                                placeholder="GHJDC67Y4HFBNDJ4RF4"
                                className="form-control"
                                onChange={event => handleDirChange(event,index)}
                              />
                            </div>
                            
                            <div className="form-groups">
                             
                              <label className="lbl">Identification Document*</label>
                             
                              <select
                                className='form-control'
                                aria-label="Default select example"
                                onChange={event => handleDirChange(event,index)}
                                name='idDoc'
                                value={form.idDoc}
                              >
                                  <option value="">Select Document Type</option>
                                  {
                                    form.issuedCountry === "Nigeria" &&
                                    <>
                                    <option  value="bvn"></option>
                                      <option  value="nin">NIN</option>
                                      <option  value="cac">Driver’s License</option>
                                      <option  value="cac">Passport</option>
                                    </>
                                  }

                                  {
                                    form.issuedCountry === "Ghana" &&
                                    <>
                                      <option  value="bvn">Voters Card</option>
                                      <option  value="nin">Driver’s License</option>
                                      <option  value="cac">Passport</option>
                                    </>
                                  }

                                  {
                                    form.issuedCountry === "Kenya" &&
                                    <>
                                      <option  value="bvn">National ID</option>
                                      <option  value="nin">Driver’s License</option>
                                      <option  value="cac">Passport</option>
                                    </>
                                  }
                                  {
                                    form.issuedCountry === "UK" &&
                                    <>
                                      <option  value="cac">Passport</option>
                                      <option  value="bvn">Others</option>
                                    </>
                                  }
                                  {
                                    form.issuedCountry === "US" &&
                                    <>
                                      <option  value="cac">Passport</option>
                                      <option  value="bvn">Others</option>
                                    </>
                                  }
                                 
                              </select>
                            </div>

                            {
                               form.issuedCountry !== "Ghana" &&
                               
                              <div className="form-groups">

                                {
                                  form.issuedCountry === "Nigeria" &&
                                  <>
                                    <label className="lbl">BVN*</label>
                                    <input
                                      type="text"
                                      name='bvn'
                                      value={form?.bvn}
                                      placeholder="Enter BVN"
                                      className="form-control"
                                    
                                      onChange={event => handleDirChange(event,index)}
                                    />
                                  </>
                                }
                                {
                                  form.issuedCountry === "Kenya" &&
                                  <>
                                  <label className="lbl">KRA PIN*</label>
                                  <input
                                    type="text"
                                    name='bvn'
                                    value={form?.bvn}
                                    placeholder="Enter KRA PIN"
                                    className="form-control"
                                  
                                    onChange={event => handleDirChange(event,index)}
                                  />
                                  </>
                                }
                              </div>
                            }

                            
                           
                          </div>
                        </form>
                        <div className="form-groups">
                          <label className="lbl">Residential Address*</label>
                          <input
                            type="text"
                            name="resAddress"
                            value={form.resAddress}
                            placeholder="Enter your full address"
                            className="form-control"
                            onChange={event => handleDirChange(event,index)}
                          />
                        </div>
                        <div className="border-bottom">
                          <div className="form-groups">
                            <label className="lbl">Valid ID for Director*</label>
                            <div 
                            className="click-to-upload"
                            onDragOver={handleDragOverD}
                            onDrop={handleDropD}
                            >
                              <input 
                                type="file"
                                name="validId"
                                onChange={(e)=>handleDirFileChange(e,index)}
                                hidden
                               
                                ref={inputRefs.current[index]}
                              />
                              <img
                                onClick={() => inputRefs.current[index].current.click()}
                                src={process.env.PUBLIC_URL + "/images/upload-icon.svg"}
                                alt="upload"
                                style={{cursor:"pointer"}}
                              />
                              {
                                DirectorID ?
                                (

                                  <>
                                  <p>{DirectorID.name}</p>
                                  <p className="file-type" style={{marginTop:"10px"}}>Click on the icon to change ID</p>
                                </>
                                  
                                ): !DirectorID &&  form.validId ?

                                <>
                                 
                                  {/* <img
                                    src={directorImg}
                                    alt={directorImg.split("/")[5]}
                                    style={{height:"50px"}}
                                  /> */}
                                  <p>{form.validId.split("/")[form.validId.split("/").length-1]}</p>
                                  <p className="file-type" style={{marginTop:"10px"}}>Click on the icon to change ID</p>
                                </>:
                                <>  
                                  <p className="click">
                                    <span onClick={() => inputRef.current.click()} style={{ color: "#1C716D" }}>Click to upload</span>
                                    or drag and drop
                                  </p>
                                  <p className="file-type">
                                    SVG, PNG,PDF JPG or GIF (max. 800x400px)
                                  </p>
                               </>
                                  
                                
                              }
                            </div>
                            
                          </div>

                          <div className="form-groups">
                            <label className="lbl">Proof of Address*</label>   
                              <div className="click-to-upload">
                                <input 
                                  type="file"
                                  name="proofOfAddress"
                                  onChange={(e)=>handleDirFileChange(e,index)}
                                  hidden
                                  ref={addressRefs.current[index]}
                                />
                                <img
                                   onClick={() => addressRefs.current[index].current.click()}
                                  src={process.env.PUBLIC_URL + "/images/upload-icon.svg"}
                                  alt="upload"
                                  style={{cursor:"pointer"}}
                                />
                                {
                                  directorAddressID ?  
                                  (
                                    <>
                                      <p >{directorAddressID?.name}</p>
                                      <p className="file-type" style={{marginTop:"10px"}}>Click on the icon to change ID</p>
                                    </>
                                  )
                                  :
                                  !directorAddressID &&  form.proofOfAddress ?
                                  <>
                                    <p>{form?.proofOfAddress?.split("/")[form?.proofOfAddress?.split("/").length-1]}</p>
                                    <p className="file-type" style={{marginTop:"10px"}}>Click on the icon to change ID</p>
                                  </>
                                  :
                                  <>
                                    <p className="click">
                                      <span onClick={() => addressRef.current.click()} style={{ color: "#1C716D" }}>Click to upload</span>
                                      or drag and drop
                                    </p>
                                    <p className="file-type">
                                      SVG, PNG,PDF JPG or GIF (max. 800x400px)
                                    </p>
                                    {/* <p className="click"></p> */}
                                  </>
                                }
                              </div>
                            {/* <p className="file-size">4.2 MB</p> */}
                          </div>
                        </div>
                        {
                        index > 0 &&
                        <p className='deletePep' onClick={() => removeDirector(index)}>
                          Delete PEP
                        </p>
                         }
                      </div>
                    )
                  })
                }
                {
                  isDirChange &&
                     <Progress percentage={dirPercentage} />
                }
                </div>
                   <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                    <div onClick={addDirector} className="add-btn" style={{cursor:"pointer"}}>
                      <img
                        src={process.env.PUBLIC_URL + "/images/plus.svg"}
                        alt="plus"
                      />
                      <p className="add-another">Add Another Director</p>
                    </div>
                   </div>
                    <button onClick={saveDirector} className='btn' style={{width:"fit-content", marginTop:"2rem"}}>
                      {
                        isDir ? (
                          <BeatLoader
                          color="#FFF"
                          cssOverride={{}}
                        />
                          
                        ) : (
                          <>
                            Save Changes
                          </>
                        )
                      }
                   
                  </button>
          </session>





          
          {/* ShareHolder */}
          <session className="main-form" id="person">
            <div className="main-form-wrapper">
              <div className="info-form-title">
                <p className="provide-info">Shareholders</p>
                <p className="share-details">
                  We are interested in learning more about your shareholders.
                </p>
              </div>
              
              <div className='choose_shareholder' >
                  <div className='legal_entity'>
                    <input
                      type="radio"
                      name="shareholder"
                      value="natural"
                      checked={shareholder === 'natural'}
                      onChange={handleShareHolder}
                    />
                    <div className="radio_group">
                      <p className="shareholder-text">Natural Person/UBOs</p>
                      <p className="other-text">
                        This shareholder is an individual
                      </p>
                    </div>
                  </div>

                  <div className='legal_entity'>
                    <input
                      type="radio"
                      name="shareholder"
                      value="legalEntity"
                      checked={shareholder === 'legalEntity'}
                      onChange={handleShareHolder}
                    />
                    <div className="icon-text">
                      <p className="shareholder-text">Legal Entity</p>
                      <p className="other-text">
                      This shareholder is a legal entity
                      </p>
                    </div>
                  </div>
                
              </div>
                
              
              {

                shareholder === 'natural' &&
                <>
                {
                  shareholderInput.map((form,index)=>{
                    return(

                      <div>
                        <form className="info-form">
                            <div className="info-form-left">
                                {/* form */}        

                              <div key={index}>
                                <div className="form-groups">
                                  <label className="lbl">First Name*</label>
                                  <input
                                    type="text"
                                    name="firstName"
                                    value={form.firstName}
                                    placeholder="Mide"
                                    onChange={event => handleNaturalChange(event,index)}
                                    className="form-control"
                                  />
                                </div>
                                <div className="form-groups">
                                  <label className="lbl">Percentage of Shares Owned*</label>
                                  <input
                                    type="text"
                                    name="percOfShares"
                                    value={form.percOfShares}
                                    onChange={event => handleNaturalChange(event,index)}
                                    placeholder="Enter position"
                                    className="form-control"
                                  />
                                </div>
                                
                              </div>
                                  
                            </div>
                            <div className="info-form-right">

                                <div className="form-groups">
                                  <label className="lbl">Last Name*</label>
                                  <input
                                    type="text"
                                    name="lastName"
                                    value={form.lastName}
                                    onChange={event => handleNaturalChange(event,index)}
                                    placeholder="Enter Name"
                                    className="form-control"
                                  />
                                </div>
                                
                                <div className="form-groups">
                                  <label className="lbl">Nationality*</label>
                                  <input
                                    type="text"
                                    name="nationality"
                                    value={form.nationality}
                                    placeholder="Enter Nationality"
                                    onChange={event => handleNaturalChange(event,index)}
                                    className="form-control"
                                    
                                  />
                                </div>
                                


                               
                                
                            </div>
                          
                        </form>
                        {
                              index > 0 &&
                              <p className='deletePep' onClick={() => removeNaturalShare(index)}>
                                delete shareHolder
                              </p>
                        }
                        
                        {/* Natural person upload */}
                        {/* <div className="border-bottom">
                          <div className="form-groups">
                            <label className="lbl">Valid ID for Shareholder*</label>
                            <div 
                            className="click-to-upload"
                            onDragOver={handleShareHolderDragOver}
                            onDrop={handleShareHolderDrop}
                            >
                              <input 
                                type="file"
                                name="validId"
                                onChange={(e)=>handleShareHolderFileChange(e,index)}
                                hidden
                                accept="image/png, image/jpeg, image/svg image/jpg"
                                ref={shareholdRef}
                              />
                              <img
                              onClick={() => shareholdRef.current.click()}
                                src={process.env.PUBLIC_URL + "/images/upload-icon.svg"}
                                alt="upload"
                                style={{cursor:"pointer"}}
                              />
                              {
                               shareholderID?
                                (
                                  <>
                                     <p>{shareholderID?.name}</p>
                                    <p className="file-type" style={{marginTop:"10px"}}>Click on the icon to change ID</p>
                                  </>
                                  
                                ): !shareholderID  && form.validId?
                                <>
                                 
                                  <p>{form?.validId?.split("/")[form.validId?.split("/").length-1]}</p>
                                  <p className="file-type" style={{marginTop:"10px"}}>Click on the icon to change ID</p>
                                </>:
                               
                                  <>
                                      
                                  <p className="click">
                                    <span onClick={() => shareholdRef.current.click()} style={{ color: "#1C716D" }}>Click to upload</span>
                                    or drag and drop
                                  </p>
                                  <p className="file-type">
                                    SVG, PNG, JPG or GIF (max. 800x400px)
                                  </p>
                                  </>
                                    
                              }
                              
                            </div>
                           
                          </div>
                          {
                                isShareChange &&
                                  <Progress percentage={sharePercentage} />
                          }
                        </div> */}
                      </div>
                    )
                  })
                }
            
                 
                </>
              }
              
              {

                shareholder === 'legalEntity' &&
                <>
                {

                legalEntity.map((form,index)=>{
                  return(
                    <div key={index}>
                      <form className="info-form">
                        <div className="info-form-left"> 
                            <div className="form-groups">
                              <label className="lbl">Name*</label>
                              <input
                                type="text"
                                name="entity_name"
                                value={form?.entity_name}
                                placeholder="Mide"
                                className="form-control"
                                onChange={event => handleEntityChange(event,index)}
                              />
                            </div>
                            <div className="form-groups">
                              <label className="lbl">Registration/Incorporation Number*</label>
                              <input
                                type="text"
                                name="Inc_Number"
                                value={form?.Inc_Number}
                                placeholder="Enter"
                                className="form-control"
                                onChange={event => handleEntityChange(event,index)}
                              />
                            </div>
                        </div>

                        <div className="info-form-right">
                          <div className="form-groups">
                            <label className="lbl">Country of Registration/Incorporation*</label>
                            <input
                              type="text"
                              name="country_of_Inc"
                              value={form?.country_of_Inc}
                              placeholder="Enter Name"
                              className="form-control"
                              onChange={event => handleEntityChange(event,index)}
                            />
                          </div>
                          <div className="form-groups">
                            <label className="lbl">Percentage of Shares Owned*</label>
                            <input
                              type="text"
                              name="percOfShares"
                              value={form?.percOfShares}
                              onChange={event => handleEntityChange(event,index)}
                              placeholder="Select"
                              className="form-control"
                            />
                          </div>
                          
                        </div>

                      </form>
                      
                      <div className="border-bottom">
                      </div>

                      {
                        index > 0 &&
                        <p className='deletePep' onClick={() => removeEntity(index)}>
                          Delete Shareholder
                        </p>
                      }
                    </div>

                  )
                })

                }
                
                </>
              }
              
            </div>
            <div className="add-btn" onClick={ shareholder === 'legalEntity'? addEntity: shareholder === 'natural'? addNaturalShare : ""}>
              <img
                src={process.env.PUBLIC_URL + "/images/plus.svg"}
                alt="plus"
              />
              <p className="add-another">Add Another Shareholder</p>
            </div>
            <button className='btn' style={{width:"fit-content", marginTop:"2rem"}} onClick={ shareholder === 'legalEntity'? saveEntity : shareholder === 'natural'? saveNaturalShare : ""}>
            {
                    isharehold ? (
                      <BeatLoader
                      color="#FFF"
                      cssOverride={{}}
                    />
                      
                    ) : (
                      <>
                        Save Changes
                      </>
                    )
            }
            </button>
          </session>

          {/* <session className="main-form" id="political">
            <div className="main-form-wrapper">
              <div className="info-form-title">
                <p className="provide-info">Politically Exposed Persons or VIPs Declaration (Optional)</p>
                <p className="share-details">
                Confirm any politically or financially exposed persons associated with you or your business.
                </p>
              </div>

              {
                Array.isArray(pep) && pep.map((form,index)=>{
                  return (
                    <div key={index}>
                      <form className="info-form">
                        <div className="info-form-left">
                          <div className="form-groups">
                            <label className="lbl">Name</label>
                            <input
                              type="text"
                              name="fullName"
                              placeholder="Mide"
                              className="form-control"
                              onChange={event => handlePepChange(event,index)}
                              value={form?.fullName}
                            />
                          </div>
                        </div>

                        <div className="info-form-right">
                          <div className="form-groups">
                            <label className="lbl">Position</label>
                            <input
                              type="text"
                              name="position"
                              placeholder="Enter Name"
                              className="form-control"
                              onChange={event => handlePepChange(event,index)}
                              value={form?.position}
                            />
                          </div>
                        </div>
                      </form>
                      <div className="form-groups">
                        <label className="lbl">Residential Address</label>
                        <input
                          type="text"
                          name="resAddress"
                          placeholder="Enter your full address"
                          className="form-control"
                          onChange={event => handlePepChange(event,index)}
                          value={form?.resAddress}
                        />
                      </div>
                      <div className="border-bottom"></div>
                      {
                        index > 0 &&
                        <p className='deletePep' onClick={() => removePep(index)}>
                          Delete PEP
                        </p>
                      }
                    </div>    
                  )
                })
              }

            </div>
            <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
              <div onClick={addPep} className="add-btn" style={{cursor:"pointer"}}>
                <img
                  src={process.env.PUBLIC_URL + "/images/plus.svg"}
                  alt="plus"
                />
                <p className="add-another">Add Another PEP</p>
              </div>

              
            </div>
              <button onClick={savePep} className='btn' style={{width:"fit-content", marginTop:"2rem"}}>
              Save Changes
            </button>
          </session> */}

          <session className="main-form" id="document">
            <div className="main-form-wrapper">
              <div className="info-form-title">
                <p className="provide-info">Document Upload</p>
                <p className="share-details">
                Submit additional documentation for faster KYC process.
                </p>
              </div>
                <div className="form-groups">
                  <label className="lbl">Certificate of Incorporation*</label>
                  <div 
                      className="click-to-upload"
                      onDragOver={handleInCertificateDragOver}
                      onDrop={handleInCertificateDrop}
                   >
                        <input 
                          type="file"
                          onChange={handleInCertificate}
                          hidden
                          name='file'
                          ref={inCertRef}
                        />
                        <img
                        onClick={() => inCertRef.current.click()}
                          src={process.env.PUBLIC_URL + "/images/upload-icon.svg"}
                          alt="upload"
                          style={{cursor:"pointer"}}
                        />
                        {
                          IncCertificateFile ?
                          (

                            <>
                              <p>{IncCertificateFile?.name}</p>
                              <p className="file-type" style={{marginTop:"10px"}}>Click on the icon to change ID</p>
                            </>
                          ):
                          !IncCertificateFile && IncCertificate?
                          <>
                            <p>{IncCertificate?.split("/")[IncCertificate?.split("/").length-1]}</p>
                            <p className="file-type" style={{marginTop:"10px"}}>Click on the icon to change ID</p>
                          </>:
                          <>
                            
                          <p className="click">
                            <span onClick={() => inCertRef.current.click()} style={{ color: "#1C716D" }}>Click to upload</span>
                            or drag and drop
                          </p>
                          <p className="file-type">
                            SVG, PNG,PDF, JPG or GIF (max. 800x400px)
                          </p>
                          </>
                            
                        }
                    </div>
                    <Progress percentage={uploadPercentage} />
                </div>

                   

                <div className="form-groups">
                  <label className="lbl">Memorandum & Article of Association (must include Director & Shareholders details)*</label>
                  <div 
                      className="click-to-upload"
                      onDragOver={handleArtAssocDragOver}
                      onDrop={handleIArtAssocDrop}
                  >
                        <input 
                          type="file"
                          onChange={handleArtAssoc}
                          hidden
                          name="articleOfAssoc"
                         
                          ref={artAssocRef}
                        />
                        <img
                          onClick={() => artAssocRef.current.click()}
                          src={process.env.PUBLIC_URL + "/images/upload-icon.svg"}
                          alt="upload"
                          style={{cursor:"pointer"}}
                        />
                            {
                          articleAssocfile ?
                          (

                            <>
                              <p>{articleAssocfile?.name}</p>
                              <p className="file-type" style={{marginTop:"10px"}}>Click on the icon to change ID</p>
                            </>
                          ):
                          !articleAssocfile && articleOfAssoc ?
                          <>
                            <p>{articleOfAssoc?.split("/")[articleOfAssoc?.split("/").length-1]}</p>
                            <p className="file-type" style={{marginTop:"10px"}}>Click on the icon to change ID</p>
                          </>:
                          <>
                            
                          <p className="click">
                            <span onClick={() => artAssocRef.current.click()} style={{ color: "#1C716D" }}>Click to upload</span>
                            or drag and drop
                          </p>
                          <p className="file-type">
                            SVG, PNG,PDF JPG or GIF (max. 800x400px)
                          </p>
                          </>
                            
                        }
                       
                            
                        
                  </div>
                  <Progress percentage={asUploadPercentage} />
                </div>

                    {/* Co2 document */}
                    <div className="form-groups">
                    <label className="lbl">CAC Status Report (Latest Details of Shareholders & Directors)*</label>
                    <div 
                        className="click-to-upload"
                        onDragOver={handleCODragOver}
                        onDrop={handleCODrop}
                    >
                        <input 
                          type="file"
                          onChange={handleCO}
                          name="co"
                          hidden
                          ref={coRef}
                        />
                        <img
                          onClick={() => coRef.current.click()}
                          src={process.env.PUBLIC_URL + "/images/upload-icon.svg"}
                          alt="upload"
                          style={{cursor:"pointer"}}
                        />
                          {
                          coFile  ?
                          (

                            <>
                          
                              <p>{coFile?.name}</p>
                              <p className="file-type" style={{marginTop:"10px"}}>Click on the icon to change ID</p>
                            </>
                          ):
                          !coFile && co ?
                          <>
                          
                            <p>{co?.split("/")[co?.split("/").length-1]}</p>
                            <p className="file-type" style={{marginTop:"10px"}}>Click on the icon to change ID</p>
                          </>:
                          <>
                            
                          <p className="click">
                            <span onClick={() => coRef.current.click()} style={{ color: "#1C716D" }}>Click to upload</span>
                            or drag and drop
                          </p>
                          </>
                            
                        }
                        
                  </div>
                  <Progress percentage={coPercentage} />
                  </div>

                <div className="form-groups">
                  <label className="lbl">Proof of Business Address (Utility Bill or Bank Statement)*</label>
                  <div 
                      className="click-to-upload"
                      onDragOver={handleUtilityDragOver}
                      onDrop={handleUtilityDrop}
                  >
                        <input 
                          type="file"
                          onChange={handleUtilityBill}
                          name="opBusUtilBill"
                          hidden
                          
                          ref={utilityRef}
                        />
                        <img
                          onClick={() => utilityRef.current.click()}
                          src={process.env.PUBLIC_URL + "/images/upload-icon.svg"}
                          alt="upload"
                          style={{cursor:"pointer"}}
                        />
                          {
                          opBusUtilBillFile  && opBusUtilBill ?
                          (

                            <>
                          
                              <p>{opBusUtilBillFile?.name}</p>
                              <p className="file-type" style={{marginTop:"10px"}}>Click on the icon to change ID</p>
                            </>
                          ):
                          !opBusUtilBillFile && opBusUtilBill ?
                          <>
                            <p>{opBusUtilBill?.split("/")[opBusUtilBill?.split("/").length-1]}</p>
                            <p className="file-type" style={{marginTop:"10px"}}>Click on the icon to change ID</p>
                          </>:
                          <>
                            
                          <p className="click">
                            <span onClick={() => utilityRef.current.click()} style={{ color: "#1C716D" }}>Click to upload</span>
                            or drag and drop
                          </p>
                          <p className="file-type">
                            SVG, PNG, JPG or GIF (max. 800x400px)
                          </p>
                          </>
                            
                        }
                        
                  </div>
                  <Progress percentage={opUploadPercentage} />
                </div>

                 
                

                {/* CO7 */}

                {/* <div className="form-groups">
                  <label className="lbl">CO7 (Particulars of Directors)*</label>
                  <div 
                      className="click-to-upload"
                      onDragOver={handleCOSevenDragOver}
                      onDrop={handleCOSevenDrop}
                  >
                        <input 
                          type="file"
                          onChange={handleCOSeven}
                          name="co"
                          hidden
                          ref={coSevenRef}
                        />
                        <img
                          onClick={() => coSevenRef.current.click()}
                          src={process.env.PUBLIC_URL + "/images/upload-icon.svg"}
                          alt="upload"
                          style={{cursor:"pointer"}}
                        />
                          {
                          coSevenFile   ?
                          (

                            <>
                          
                              <p>{coSevenFile?.name}</p>
                              <p className="file-type" style={{marginTop:"10px"}}>Click on the icon to change ID</p>
                            </>
                          ):
                          !coSevenFile && coSeven ?
                          <>
                            <p>{coSeven?.split("/")[coSeven?.split("/").length-1]}</p>
                            <p className="file-type" style={{marginTop:"10px"}}>Click on the icon to change ID</p>
                          </>:
                          <>
                            
                          <p className="click">
                            <span onClick={() => coSevenRef.current.click()} style={{ color: "#1C716D" }}>Click to upload</span>
                            or drag and drop
                          </p>
                          </>
                            
                        }
                        
                  </div>
                  <Progress percentage={coSevenPercentage} />
                </div> */}

                {/* Additional documents  */}
      
                  <div className="form-groups">
                    <label className="lbl">Additional documents <span style={{color:"#D0D5DD"}}>(optional)</span></label>
                      {
                        additionDoc.map((form,index)=>{
                          const currentRef = lineRefs.current[index];
                         
                          return(
                            <div 
                                className="click-to-upload"
                                onDragOver={(e)=>handleAdditionDragOver(e,index)}
                                onDrop={(e)=>handleAdditionDrop(e,index)}
                            >
                                  <input 
                                    type="file"
                                    onChange={(e)=>handleAdditional(e,index)}
                                    name="doc"
                                    hidden
                                    ref={currentRef}
                                  />
                                  
                                  <img
                                    onClick={() => currentRef?.current.click()}
                                    src={process.env.PUBLIC_URL + "/images/upload-icon.svg"}
                                    alt="upload"
                                    style={{cursor:"pointer"}}
                                  />
                                    {
                                    form?.doc ?
                                    (
                                      <>
                                        <p>{form?.doc?.split("/")[form?.doc?.split("/").length-1]}</p>
                                        <p className="file-type" style={{marginTop:"10px"}}>Click on the icon to change ID</p>
                                      </>
                                    ):
                                    <>
                                      
                                    <p className="click">
                                      <span onClick={() => currentRef?.current.click()} style={{ color: "#1C716D" }}>Click to upload</span>
                                      or drag and drop
                                    </p>
                                    <p className="file-type">
                                    For example: Foreign holding entity docs, Board Consent (where necessary)
                                    </p>
                                    </>
                                      
                                  }
                                  {
                                  index > 0 &&
                                  <img src="/images/trash.svg" alt="" style={{ marginRight:"5px",color:"#FFF"}} className='deletePep' onClick={() => removeDoc(index) }/>
                                  // <p style={{background:"#ED1C24", marginRight:"5px",color:"#FFF"}} className='deletePep' onClick={() => removeDoc(index) }>
                                  //   Delete Document
                                  // </p>
                                  }
                                  
                            </div>
                          )
                        })
                      }
                    <Progress percentage={additionPercentage} />
                  </div>
                 
                  <div style={{width:"fit-content"}}  className="add-btn" onClick={addOptionalDoc}>
                    <img
                      src={process.env.PUBLIC_URL + "/images/plus.svg"}
                      alt="plus"
                    />
                    <p className="add-another">Add Additional Documents</p>
                  </div>
            </div>

            {
              isDoc ?
              <button style={{width:"fit-content", marginTop:"30px"}} className='btn'>
                  <BeatLoader
                  color="#FFF"
                  cssOverride={{}}
                  />
              </button>:

              <button className='btn' style={{width:"fit-content",marginTop:"1rem", marginBottom:"1rem", marginLeft:"auto"}} onClick={saveDocuments}> 
                Save Changes 
              </button>
            }


            {
              isFormSubLoading ?

              <button style={{width:"fit-content",marginTop:"50px"}} className='btn'>
                  <BeatLoader
                  color="#FFF"
                  cssOverride={{}}
                  />
              </button>:
              <button onClick={handleSubmitKyc} style={{width:"fit-content",marginTop:"50px"}} className='btn'>Submit KYC Details</button>
            }
              
          </session>
          </div>
        </div>
    
    </div>
  )
}

export default IntroduceYourBusiness