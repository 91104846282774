import React, { createRef, useState } from 'react'

import '../common/tables.scss'
import { format } from 'timeago.js';
import { useNavigate } from 'react-router-dom'
import { copyToClipboard } from '../utilities/clipboardUtils';
import TableHeaderSearch from '../common/TableHeaderSearch';
import { NumericFormat } from 'react-number-format';
import Status from '../common/Status';
import TableLoader from '../common/TableLoader';
import useFilterAndPagination from '../hooks/useFilterAndPagination';
import CustomPagination from '../common/CustomPagination';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';

function OtcTable({collectionData,records,setSelectedCurrency,getCollection,selectedCurrency,isOtcCollectionLoading,balanceData}) {
    const lineRefs = React.useRef([]);
    const [selectedValue,setSelectedValue] = useState('all')
    const rowsPerPage = 10;

    // Use the custom hook and pass collectionData
  const {
    currentPage,
    searchTerm,
    setSearchTerm,
    dateRange,
    handleDateChange,
    handlePageChange,
    paginatedRecords,
    filteredRecords,
    setIsSearchTermChanged
  } = useFilterAndPagination(collectionData, rowsPerPage);


   //   Custom styles

  const customStyles = {
    header: {
      style: {
        backgroundColor: '#f9fafb',
      },
    },

    headRow: {
      style: {
        backgroundColor: '#f9fafb',
       
      },
    }
  
    
  };


     // Set the number of rows per page
     lineRefs.current = collectionData.map((_, i) => lineRefs.current[i] ?? createRef());

    const handleToggleModal = ()=>{
        // toggleModal();
    }
    const handleSelectedcurrency = (e)=>{
        setSelectedCurrency(e.target.value)
    
        getCollection(e.target.value)
       
    }

    const handleCopyToClipboard = (ref) => {
        navigator.clipboard.writeText(ref.current.value);
        toast.success('Copied successfully');
    };
    

    const columns = [
        {
          name: 'Timestamp',
          selector: row => format(new Date(row.dated), 'yyyy-MM-dd HH:mm:ss'),
          sortable: true,
        },
        {
          name: 'Reference',
          cell: (row, index) => (
            <div style={{ display: 'flex', gap: '5px' }}>
              <input ref={lineRefs.current[index]} className='link_table' value={row.transactionid} hidden readOnly />
              <h1 className='td-text'>{row.transactionid.length > 10 ? `${row.transactionid.slice(0, 11)}...` : row.transactionid}</h1>
              <img
                src={process.env.PUBLIC_URL + "/images/copy-key-icon.svg"}
                alt="ic"
                onClick={() => handleCopyToClipboard(lineRefs.current[index])}
                style={{ cursor: 'pointer' }}
              />
            </div>
          ),
          sortable: true,
        },
        {
          name: 'Amount',
          cell: row => (
            <NumericFormat
              value={Number(row.total_amount / 100).toFixed(2)}
              displayType={'text'}
              thousandSeparator={true}
              prefix={`${row.currency_settled} `}
              renderText={value => <p className='td-text'>{value}</p>}
            />
          ),
          sortable: true,
        },
        {
          name: 'Balance',
          cell: row => (
            <NumericFormat
              value={Number(row.balance_after / 100).toFixed(2)}
              displayType={'text'}
              thousandSeparator={true}
              prefix={`${row.currency_settled} `}
              renderText={value => <p className='td-text'>{value}</p>}
            />
          ),
          sortable: true,
        },
        {
          name: 'Type',
          cell: row => (
            <Status
              mgs={row.type}
              bg="#FDF2FA"
              border="#FCCEEE"
              color="#C11574"
            />
          ),
          sortable: true,
        },
        {
          name: 'Description',
          selector: row => row.narration.length > 30 ? `${row.narration.slice(0, 31)}...` : row.narration,
          sortable: true,
        },
        {
          name: 'Status',
          cell: row => (
            <div className='payment-status'>
              {row.status === "success" || row.status === "Success" ? (
                <Status
                  icon="/images/success.svg"
                  mgs="Successful"
                />
              ) : row.status === "pending" ? (
                <Status
                  icon="/images/Dot.svg"
                  mgs="Pending"
                  bg="#FFF8F0"
                  border="#F79009"
                  color="#F79009"
                />
              ) : row.status === "failed" ? (
                <Status
                  icon="/images/x.svg"
                  mgs="Failed"
                  bg="#FEF3F2"
                  border="#FECDCA"
                  color="#B42318"
                />
              ) : null}
            </div>
          ),
          sortable: true,
        },
      ];

  return (
    <div className='balance-overview'>
     {
        isOtcCollectionLoading &&
        <TableLoader />
     }
     {Array.isArray(collectionData) && collectionData.length > 0 ? (
        <>
          <DataTable
            columns={columns}
            data={paginatedRecords}
            highlightOnHover
            // striped
            subHeader
            paginationTotalRows={filteredRecords.length}
            paginationDefaultPage={currentPage}
            paginationPerPage={rowsPerPage}
            customStyles={customStyles}
            onChangePage={handlePageChange}
            pagination={false} // Disable default pagination
            subHeaderComponent={
              <TableHeaderSearch
                title="Transactions"
                placeholder="Search by Reference, Timestamp  or type..."
                otc
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
                getCollection={getCollection}
                balanceData={balanceData}
                searchTerm={searchTerm}
                setSearchTerm={(term) => {
                  setSearchTerm(term);
                  setIsSearchTermChanged(true);
                }}
                dateRange={dateRange}
                handleDateChange={handleDateChange}
              />
            }
          />
          <CustomPagination
            totalRows={filteredRecords.length}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            setCurrentPage={handlePageChange}
          />
        </>
      )  : (
        !isOtcCollectionLoading && (
         
        <table className='tbl'>
            <tr>
            <td colSpan="7" className='tbl-heading'>
            <TableHeaderSearch
                title="Transactions"
                placeholder="Search by name, acc num or reff..."
                otc
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
                getCollection={getCollection}
                searchTerm={searchTerm}
                setSearchTerm={(term) => {
                setSearchTerm(term);
                }}
                dateRange={dateRange}
                handleDateChange={handleDateChange}
            />
            </td>
        </tr>

        <tr>
            <td  colSpan={7} style={{width:"100%"}}>
            <div className='empty_tbl'>
                <img src='/images/emptyPage.svg' alt='emptuy' />
                <h3 className='num_trans'>No Transactions Yet</h3>
                <p className='num_desc'> it is necessary to perform transactions on your Flick account</p>
            </div>
            </td>
        </tr>
        </table>
        )
      )}
    </div>
  )
}

export default OtcTable